import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import userPh from '../../../images/user_ph.svg'
import { Button } from '../../../components/system/Button'
import { Input } from '../../../components/system/Input'

import { FullUserFragment } from '../operations/__generated__/full-user.generated'
import { useEffect } from 'react'
import { useUpdateUserMutation } from '../operations/__generated__/update-user.generated'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Loader } from '../../../components/common/Loader'
import { Image2x } from '../../../components/system/Image2x'

export const UserInfo: React.FC<{ data?: FullUserFragment }> = (data) => {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation();
  const { handleSubmit, register, reset } = useForm({ mode: 'onChange' })
  
  const [updateUser, {loading: loadingUpdate}]= useUpdateUserMutation({
    onCompleted: ({updateUser: updateUserData})=> {
      if(updateUserData.error){
        toast.error(t('errors.fetch'))
      }
      if(updateUserData.success && updateUserData.user){
        reset(updateUserData.user)
        toast.success(t("users.success.update_user"));
      }
    }
  });

  useEffect(() => {
    if (data) {
      reset(data.data)
    }
  }, [data, reset])


  return (
      <FormContainer onSubmit={handleSubmit((formData) => updateUser({variables:  {id: id!, data: _.omit(formData,['profile_picture', '__typename', 'id', 'role', 'created_at']) } }) ) } >
        {!loadingUpdate && ( <MainInfoContainer>

          
          <FieldsContainer>
            <Input
              name="first_name"
              size="small"
              placeholder="users.first_name"
              label="users.first_name"
              innerRef={register}
              />
            <Input
              name="last_name"
              size="small"
              placeholder="users.last_name"
              label="users.last_name"
              innerRef={register}
              />
            <Input
              name="email"
              size="small"
              placeholder="users.email"
              label="users.email"
              disabled
              innerRef={register}
              />
          </FieldsContainer>
          {data.data && <ProfilePictureContainer >
           { data.data.profile_picture 
              ? <Image2x id={data.data.profile_picture.id}  /> 
              : <PlaceHolder src={ userPh } alt="profile"/>
            }
          </ProfilePictureContainer>}
        </MainInfoContainer>)}
        {loadingUpdate  && (
          <Loader size='large' />
        )}
        <FooterContainer>

          <Button
            type="submit"
            text="users.save"
            color="secondary"
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`

const MainInfoContainer = styled.div`
  width:100%;
  display:flex;
  justify-content: space-between;
  @media screen and (max-width: 880px){
    flex-direction: column-reverse;
  }
`

const ProfilePictureContainer = styled.div`
  width: 26%;
  margin-left: 3%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing:border-box;
  @media screen and (max-width: 1080px){
    justify-content: flex-end;
    width:40%;
    padding: 6% 0 6% 6%;
  }
  @media screen and (max-width: 880px){
    width: 200px;
    height:200px;
    margin-left: auto;
    margin-right: auto;
  }
  > .img2x {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
`


const PlaceHolder = styled.img`
border-radius: 999px;
    overflow: hidden;
`

const FieldsContainer = styled.div`
  display: flex;
  width:80%;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1080px){
    width: 60%;
   > * {

     flex-basis: 100%;
   } 
   @media screen and (max-width: 880px){
    width: 100%;
  }
    
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  box-sizing:border-box;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 40px 0;
`

import styled from 'styled-components'
import { Link as _Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ColorName, useTheme } from '../../themes'
import { I18NKey } from '../../../../i18n'

type Props = {
  to: string
  text?: I18NKey
  ntText?: string
  color?: ColorName
  style?: Record<string, unknown>
  children?: React.ReactNode
}

const CellLink: React.FC<Props> = ({
  to,
  text,
  ntText: textVariable,
  color = 'black',
  style,
  children,
}) => {
  const { t } = useTranslation()
  const themes = useTheme()

  return (
    <StyledLink to={to} color={themes.theme[color]} style={style}>
      {text && t(text)}
      {textVariable}
      {children}
    </StyledLink>
  )
}

export default CellLink

type StyledProps = { color: string }
const StyledLink = styled(_Link)<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${(props) => props.color};
  :visited {
    color: ${(props) => props.color};
  }
  :hover {
    opacity: 0.8;
  }
`

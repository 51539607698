import styled from 'styled-components'
import { Link as _Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { I18NKey } from '../../../i18n'

type Props = {
  to: string
  text?: I18NKey
  ntText?: string
  color?: string
  style?: Record<string, unknown>
}

export const Link = ({ to, text, ntText: textVariable, color = '$primary', style }: Props) => {
  const { t } = useTranslation()

  return (
    <StyledLink to={to} color={color} style={style}>
      {text && t(text)}
      {textVariable}
    </StyledLink>
  )
}

type StyledProps = { color: string }
const StyledLink = styled(_Link)<StyledProps>`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.color};
  :visited {
    color: ${(props) => props.color};
  }
  :hover {
    opacity: 0.8;
  }
`

import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CustomTabs } from "../../components/tabs/CustomTabs";
import {  useState } from "react";
import toast from "react-hot-toast";
import { useGetPetQuery } from "./operations/__generated__/getPet.generated";
import { FullPetFragment } from "./operations/__generated__/full-pet.generated";
import { useTranslation } from "react-i18next";
import { PetTreatments } from "./tabs/PetTreatments";
import { PetInfo } from "./tabs/PetInfo";
import { PetSubOwners } from "./tabs/PetSubOwners";
import { Section } from "../../components/common/Section";

export const PetDetail = () => {
  const { id } = useParams<{ id: string }>();
  const location = "info";
  const { t } = useTranslation();
    const [data, setData] = useState<FullPetFragment>()
    // eslint-disable-next-line 
    const { loading } = useGetPetQuery({
      variables: {id: id! },
      onCompleted:({getPet})=> {
        if(getPet.error){
           return toast.error(t('errorts.fetch'))
        }
        if(getPet.success && getPet.pet ){
            setData(getPet.pet)
        }
    },
    onError:()=> {
          return toast.error(t('errorts.fetch'))
      }
  })

  return (
    <Section>
       {data && (
        <CustomTabs
          defaultValue={location ?? "info"}
          entries={[
            {
              value: "info",
              label: "pets.general_data",
              node: <PetInfo data={data}/>,
            },
            { value: "ownerships", label: "pets.ownerships", node:<PetSubOwners /> },
            { value: "treatments", label: "pets.treatments", node: <PetTreatments /> },
            
          ]}
        />
      )}
    </Section>
  );
};


import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TradInput } from "./tradInput";
import { useTheme } from "../../../components/system/themes";
import { Icon } from "../../../components/system/Icon";

interface FakeData {
	[key: string]: any;
}

type Props = {
	data: FakeData;
	languages: string[];
	depth?: number;
	parents?: string[];
};

// Recursive component for rendering fields
export const LanguagesFields: React.FC<Props> = React.memo(({
	data,
	languages,
	depth = 0,
	parents = [],
}) => {
	const [openKeys, setOpenKeys] = useState<{ [key: string]: boolean }>({});
	const [openValues, setOpenValues] = useState<{ [key: string]: boolean }>(
		{}
	);

	const toggleOpen = (key: string) => {
		setOpenKeys((prevOpenKeys) => ({
			...prevOpenKeys,
			[key]: !prevOpenKeys[key],
		}));
	};

	const toggleOpenValue = (key: string) => {
		setOpenValues((prevOpenValues) => ({
			...prevOpenValues,
			[key]: !prevOpenValues[key],
		}));
	};
	const theme = useTheme()

	return (
		<Wrapper depth={depth} open={true}>
			{Object.keys(data).map((key) => {
				if (key === "_values") {
					return (
						<div key={key}>
							{data[key].map((value: any) => (
								<ValueWrapper key={value.key}>
									<h3
										onClick={() =>
											toggleOpenValue(value.key)
										}
										style={{
											color:
												value.missing &&
													!languages.every((lang) =>
														value.missing?.includes(
															lang
														)
													)
													? "#f00000"
													: "",
										}}
									>
										{value.key.replaceAll("_", " ")}
									</h3>
									<ContentWrapper
										className="inputs"
										depth={depth}
										open={openValues[value.key]}
									>
										{languages.map((lang) => (
											<React.Fragment key={lang}>
												<TradInput
													name={[lang, ...parents, value.key].join('.')}
													placeholder={lang}
													label={lang}
													missing={value.missing &&
														!languages.every((lang) =>
															value.missing?.includes(
																lang
															)
														)}
												/>
											</React.Fragment>
										))}
									</ContentWrapper>
								</ValueWrapper>
							))}
						</div>
					);
				}

				if (typeof data[key] === "object" && data[key] !== null) {
					const newParents = [...parents, key];
					return (
						<div key={key} className="parent-wrapper">
							<Header
								style={{
									color: data[key]._child_missing
										? "#f00000"
										: "",
								}}
								depth={depth}
								onClick={() => toggleOpen(key)}
							>
								{key.replaceAll("_", " ")}   {data[key]._child_missing && <Icon name="alert-circle-outline" size="22px" color={theme.theme["danger"]} />}
							</Header>
							<ContentWrapper depth={depth} open={openKeys[key]}>
								<LanguagesFields
									data={data[key]}
									languages={languages}
									depth={depth + 1}
									parents={newParents}
								/>
							</ContentWrapper>
						</div>
					);
				}

				return null;
			})}
		</Wrapper>
	);
});

type DepthProps = {
	depth: number;
};

type WrapperProps = DepthProps & {
	open: boolean;
};

// Styled components
const Wrapper = styled.div<WrapperProps>`
	padding-left: ${(props) => 24 * props.depth}px;
`;

const ContentWrapper = styled.div<WrapperProps>`
	max-height: ${(props) => (props.open ? "unset" : "0")};
	opacity: ${(props) => (props.open ? "1" : "0")};
	overflow: hidden;
	transition: opacity 0.5s ease-in, max-height 0.5s ease-in;
	&.inputs {
		display: flex;
		flex-direction: column;
		> * {
			margin-bottom: 10px;
		}
	}
`;

const Header = styled.h2<DepthProps>`
	font-size: ${(props) => Math.max(30 - 3 * props.depth, 10)}px;
	cursor: pointer;
	text-transform: capitalize;
	display: flex; 
	align-items:center;
	gap: 12px;
	span {
		font-weight: 400;
		font-size: 15px;
	}
`;

const ValueWrapper = styled.div`
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	input {
		margin: 5px;
	}
	h3 {
		text-transform: capitalize;
		cursor: pointer;
		span {
			font-weight: 400;
			font-size: 15px;
		}
	}
`;

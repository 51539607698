import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type SimpleBodyFragment = (
  { __typename?: 'PetBody' }
  & Pick<Types.PetBody, 'family' | 'breed' | 'id'>
);

export const SimpleBodyFragmentDoc = gql`
    fragment SimpleBody on PetBody {
  family
  breed
  id
}
    `;
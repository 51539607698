import styled, { CSSObject } from 'styled-components'

interface Props {
  size: number | string
  onClick: () => void
  color: CSSObject['color']
  backgroundColor: CSSObject['backgroundColor']
}

const PageSize = ({ size, backgroundColor, color, onClick }: Props) => {
  return (
    <Container color={color} backgroundColor={backgroundColor} onClick={onClick}>
      {size}
    </Container>
  )
}

export default PageSize

interface ContainerProps {
  color: CSSObject['color']
  backgroundColor: CSSObject['backgroundColor']
}

const Container = styled.div<ContainerProps>`
  padding: 4px 2px;
  justify-content: center;
  width: 36px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: 0 5px;
  cursor: pointer;
  align-items: center;
  border-radius: 25px;
  font-size: 14px;
  display: flex;
`

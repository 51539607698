import { useEffect, useRef, useState } from "react"
import styled from "styled-components"


type props = {
    id: string,
    alt?: string, 
    fit?: boolean,
    rounded?: boolean
}

export const Image2x: React.FC<props>= ({id, alt, fit= false, rounded=false})=> {
    const [src,setSrc] = useState<string>() ;
    const [src2x, setSrc2x] =useState<string>() ; 
    const ref = useRef<HTMLDivElement>(null)
    useEffect(()=>{
        const baseUrl = `${process.env.REACT_APP_BASE_URL?.replace('graphql', 'media')}/${id}`
        setSrc (`${baseUrl}/${ref.current ? ref.current.offsetWidth : 0}x${ref.current ? ref.current.offsetHeight : 0}${fit ? '/fit' : ''}`)
        setSrc2x (`${baseUrl}/${ref.current ? ref.current.offsetWidth*2 : 0}x${ref.current ? ref.current.offsetHeight*2 : 0}${fit ? '/fit' : ''}`)
    },[fit, id])
    return <ImageContainer className="img2x" ref={ref} rounded>
        {src && src2x &&<img src={src} alt={alt} srcSet={`${src2x} 2x`} />}
    </ImageContainer>
}

const ImageContainer = styled.div<{rounded: boolean}>`
    ${({rounded})=> rounded && `border-radius: 999px; overflow: hidden;`}
    > img {
        width: 100%;
        height:  100%;
    }
`
import { useParams } from "react-router-dom";

import { FullPetFragment } from "../operations/__generated__/full-pet.generated";

import { TabsContainer } from "../../../components/tabs/TabsContainer";
import styled from "styled-components";
import { useState } from "react";
import { PetTreatmentFragment } from "../operations/__generated__/pet-treatment.generated";
import { useGetPetTreatmentsQuery } from "../operations/__generated__/getPetTreatments.generated";
import { CustodyLevel, Maybe } from "../../../types";
import toast from "react-hot-toast";
import { Loader } from "../../../components/common/Loader";
import { TreatmentItem } from '../../../components/common/TreatmentItem'
import { treatmentColors } from "../../../utils/costants/tagColors";
import { Subtitle } from "../../../components/system/Typography";

export const PetTreatments: React.FC<{ data?: FullPetFragment }> = (data) => {
  const { id } = useParams<{ id: string }>();
  const [page] = useState(0);
  const [items, setItems] = useState<Maybe<PetTreatmentFragment>[]>([]);
  const { loading } = useGetPetTreatmentsQuery({
    variables: {
      page: page,
      petId: id!,
      custodyLevels: [
        CustodyLevel.Owner,
        CustodyLevel.PetSitter,
        CustodyLevel.SubOwner,
      ],
    },
    onCompleted: ({ listTreatments }) => {
      if (listTreatments.error) {
        toast.error("errors.fetch");
      }
      if (
        listTreatments.success &&
        listTreatments.items &&
        listTreatments.items.length > 0
      ) {
        setItems(listTreatments.items);
      }
    },
    onError: () => {
      toast.error("errors.fetch");
    },
  });

  return (
    <TabsContainer>
      <TreatmentsListContainer>
        {!loading && items.length > 0 && (
          <TreatmentsList>
            {items.map(item=> item && <TreatmentItem key={item.id} treatment={item} backColor={treatmentColors[item.type]} />)}
          </TreatmentsList>
          )
        }
        {!loading && items.length === 0 && (
          <Subtitle text="table.no_items" color="black" />
          )
        }
        {loading && <Loader size="medium" />}
      </TreatmentsListContainer>
    </TabsContainer>
  );
};

const TreatmentsList = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
`;

const TreatmentsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

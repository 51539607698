import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { SearchFilters } from '../../../../utils/types'
import { Button } from '../../Button'
import { Input } from '../../Input'
// import FilterBox from './FilterBox'

type Props = {
  text: string
  filters?: SearchFilters[]
  onFiltersChange?: (val: SearchFilters) => void
  onSubmit: (search_text: string) => void
}

const FilterBar: React.FC<Props> = ({ text, onSubmit, filters = [], onFiltersChange }) => {
  // const [showBox, setShowBox] = useState(false)

  const { register, handleSubmit } = useForm<{ search: string }>({
    defaultValues: {
      search: text,
    },
  })

  return (
    <FiltersContainer>
      <SearchBar
        onSubmit={handleSubmit((data) => {
          onSubmit(data.search)
        })}
      >
        <SearchBox>
           <Input
            className="search"
            size="medium"
            innerRef={register}
            autocomplete="off"
            name="search"
            label=""
            placeholder="filters.search_placeholder"
          />
      {/* {onFiltersChange && (
            <FilterBox
              onOpen={() => setShowBox(true)}
              onClose={() => setShowBox(false)}
              show={showBox}
              onCheckChange={(value) => {
                onSubmit(getValues('search'))
                onFiltersChange(value)
              }}
              checks={filters}
            ></FilterBox>
          )} */}
        </SearchBox>
        <Button text="filters.search" type="submit" color="secondary" />
      </SearchBar>
    </FiltersContainer>
  )
}

export default FilterBar

const FiltersContainer = styled.div`
  width: 100%;
`
const SearchBar = styled.form`
  width: 100%;
  display: flex;
  flex-flow: wrap;
  padding: 20px 0;
  justify-content: right;
  > button {
    width: auto;
    padding: 5px 40px;
    height: fit-content;
  }
`
const SearchBox = styled.div`
  width: 33%;
  max-width: 300px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  input {
    height: 14px;
  }
`

import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type PetOwnershipsFragment = (
  { __typename?: 'PaginatedOwnerships' }
  & Pick<Types.PaginatedOwnerships, 'success'>
  & { items: Array<Types.Maybe<(
    { __typename?: 'Ownership' }
    & Pick<Types.Ownership, 'id' | 'custody_level'>
    & { user: (
      { __typename?: 'User' }
      & Pick<Types.User, 'first_name' | 'last_name' | 'email' | 'id'>
    ) }
  )>>, pagination: (
    { __typename?: 'Pagination' }
    & Pick<Types.Pagination, 'current_page' | 'page_size' | 'total_items' | 'total_pages'>
  ) }
);

export const PetOwnershipsFragmentDoc = gql`
    fragment PetOwnerships on PaginatedOwnerships {
  items {
    id
    custody_level
    user {
      first_name
      last_name
      email
      id
    }
  }
  success
  pagination {
    current_page
    page_size
    total_items
    total_pages
  }
}
    `;
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CustomTabs } from "../../components/tabs/CustomTabs";
import { UserInfo } from "./tabs/UserInfo";
import { useState } from "react";
import  {UserPets} from './tabs/UserPets'
import { FullUserFragment } from "./operations/__generated__/full-user.generated";
import { useGetUserQuery } from "./operations/__generated__/getUser.generated";
import toast from "react-hot-toast";
import { UserTreatments } from "./tabs/UserTreatments";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/common/Loader";
import { Section } from "../../components/common/Section";

export const UserDetail = () => {
  const { id } = useParams<{ id: string }>();
  const location = "info";
  const [data, setData] = useState<FullUserFragment>();

  const { t }= useTranslation();
  // eslint-disable-next-line 
  const { loading } = useGetUserQuery({
    variables: { id: id! },
    onCompleted: ({getUser}) => {
      if(getUser.error) {
        toast.error('errors.user')
        return 
      }
      if (getUser.success && getUser.user ){
        setData(getUser.user)
      }
    },
    onError: () => {
      return toast.error(t('errors.fetch'))
    }
  });

  return (
    <Section>
      {data && (
        <CustomTabs
          defaultValue={location ?? "info"}
          entries={[
            {
              value: "info",
              label: "users.personal_data",
              node: <UserInfo data={data} />,
            },
            { value: "pets", label: "users.pets", node: <UserPets /> },
            { value: "treatments", label: "users.treatments", node: <UserTreatments /> },
            
          ]}
        />
      )}
      {loading && (<LoaderContainer>
                  <Loader size="large" />
        </LoaderContainer > )}
    </Section>
  );
};



const LoaderContainer = styled.div`
  width:100%;
  padding-top:120px;
  display: flex;
  justify-content: center;
`
import styled, { CSSObject } from 'styled-components'

interface Props {
  flexDirection?: CSSObject['flexDirection']
  justifyContent?: CSSObject['justifyContent']
  alignItems?: CSSObject['alignItems']
  padding?: CSSObject['padding']
  width?: CSSObject['width']
  height?: CSSObject['height']
  maxWidth?: CSSObject['width']
  maxHeight?: CSSObject['height']
  minWidth?: CSSObject['width']
  minHeight?: CSSObject['height']
  color?: CSSObject['color']
  cursor?: CSSObject['cursor']
  background?: CSSObject['backgroundColor']
}
export const Flex = styled.div<Props>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  min-width: ${({ minWidth }) => minWidth};
  min-height: ${({ minHeight }) => minHeight};
  cursor: ${({ cursor }) => cursor};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`

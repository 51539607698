
import styled, { CSSObject } from "styled-components";
import { useForm } from "react-hook-form";
import { Button } from "../../components/system/Button";
import { Input } from "../../components/system/Input";
import { Link } from "./components/Link";
import { storage } from "../../data/storage";
import { useLoginMutation } from "./operations/__generated__/login.generated";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTheme, themes as themesConf } from "../../components/system/themes";
import LogoLight from "../../images/logo-light.svg";
import LogoDark from "../../images/logo-dark.svg";
// import { storage } from '../../data/storage'

export const LoginPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const themes = useTheme();
  const logo = themes.theme === themesConf.dark ? LogoDark : LogoLight;

  const [login, { loading }] = useLoginMutation({
    onCompleted: ({ login }) => {
      if (login.error) {
        toast.error(t("errors.login"));
        return;
      }
      if (login.user && login.token) {
        storage.saveToken(login.token);
        storage.saveUser(login.user);
        navigate("/me");
      }
    },
    onError: (error) => {
      toast.error(t("errors.login"));
    },
  });
  const { register, handleSubmit } = useForm({ mode: "onChange" });

  return (
    <Container
      bgColor={themes.theme["white"]}
      onSubmit={handleSubmit((variables) => login({ variables } as any))}
    >
      <Logo src={logo} alt="Logo" />
      <Input
        name="email"
        type="email"
        label=""
        placeholder="auth.insert_email"
        size="small"
        style={{ marginBottom: "30px" }}
        innerRef={register}
      />
      <Input
        name="password"
        type="password"
        label=""
        placeholder="auth.insert_password"
        size="small"
        innerRef={register}
        style={{ marginBottom: "30px" }}
      />
      <Link to="/password-reset" text="auth.forgot_password_link"  />
      <Button
        text="auth.login"
        radius={4}
        loading={loading}
        style={{ width: "50%" }}
        color="secondary"
        rightIcon="enter"
        iconColor={"white"}
      />
    </Container>
  );
};

const Container = styled.form<{ bgColor: CSSObject["color"] }>`
  width: 100%;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 10vh;
  box-sizing: border-box;
  height: 100vh;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > a {
    margin-bottom:50px;
  }
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 30px;
  object-fit: contain;
`;

import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type FullUserFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'email' | 'last_name' | 'first_name' | 'role' | 'created_at'>
  & { profile_picture?: Types.Maybe<(
    { __typename?: 'Media' }
    & Pick<Types.Media, 'type' | 'id'>
  )> }
);

export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  id
  email
  last_name
  first_name
  role
  created_at
  profile_picture {
    type
    id
  }
}
    `;
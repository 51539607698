import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ColorName, useTheme } from '../../themes'
import { I18NKey } from '../../../../i18n'
import { OrderDirection } from '../../../../utils/types'
import { Icon } from '../../Icon'

type Props = {
  text?: I18NKey
  ntText?: string
  color?: ColorName
  onClick: (order_by?: string, order_direction?: OrderDirection) => void
  orderBy: string
  ordering: { order_by?: string; order_direction?: OrderDirection }
  style?: Record<string, unknown>
}

const OrderableHeaderCell: React.FC<Props> = ({
  text,
  ntText: textVariable,
  color = 'black',
  style,
  onClick,
  ordering,
  orderBy,
}) => {
  const { t } = useTranslation()
  const themes = useTheme()

  return (
    <Container
      color={themes.theme[color]}
      style={style}
      onClick={() => {
        if (ordering.order_direction === OrderDirection.ASC && ordering.order_by === orderBy) {
          return onClick(orderBy, OrderDirection.DESC)
        }
        if (ordering.order_direction === OrderDirection.DESC && ordering.order_by === orderBy) {
          return onClick(undefined, undefined)
        }
        return onClick(orderBy, OrderDirection.ASC)
      }}
    >
      {text && t(text)}
      {textVariable}
      {ordering.order_direction === OrderDirection.ASC && ordering.order_by === orderBy && (
        <Icon size="20px" name="chevron-down-outline" color={themes.theme["black"]}></Icon>
      )}
      {ordering.order_direction === OrderDirection.DESC && ordering.order_by === orderBy && (
        <Icon size="20px" name="chevron-up-outline" color={themes.theme["black"]}></Icon>
      )}
      {((ordering.order_direction !== OrderDirection.ASC && ordering.order_by !== orderBy) ||
        ordering.order_by !== orderBy) && (
        <Icon className="hide" size="12px" name="chevron-down-outline" color={themes.theme["gray"]}></Icon>
      )}
      {ordering.order_direction === OrderDirection.ASC && ordering.order_by === orderBy && (
        <Icon className="hide" size="12px" name="chevron-up-outline" color={themes.theme["gray"]}></Icon>
      )}
      {ordering.order_direction === OrderDirection.DESC && ordering.order_by === orderBy && (
        <Icon className="hide" size="12px" name="remove-circle-outline" color={themes.theme["gray"]}></Icon>
      )}
    </Container>
  )
}

export default OrderableHeaderCell

type StyledProps = { color: string }
const Container = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${(props) => props.color};
  > .hide {
    visibility: hidden;
  }
  :hover > .hide {
    visibility: visible;
  }
  :hover {
    cursor: pointer;
  }
  > :nth-child(1) {
    margin-left: 5px;
  }
`

import { useTranslation } from "react-i18next";
import { Maybe } from "../../../types";

export const composeUniqueList = <T>(
    values: [string, T][],
    newElements: string[] | Maybe<string>[],
    baseLabel?: string
): { value: T; label: string }[] => {
    return [
        ...newElements
            .map((diet) => ({
                value: (diet as unknown) as T,
                label: (diet as string).toLowerCase(),
            }))
            .filter(
                (opt) =>
                    !values.map(([label, value]) => value).includes(opt.value)
            ),
        ...values.map(([label, value]) => ({
            value,
            label: useTranslation().t(
                `${baseLabel ? `${baseLabel}.` : ""}${label.toLowerCase()}`
            ),
        })),
    ];
};

import { Section } from "../../components/common/Section";
import { CustomTabs } from "../../components/tabs/CustomTabs";
import { UsersActivity } from "./tabs/UsersActivity";

export const StatisticsPage = () => {
	const location = "usersActivity";
	return (
		<Section>
			<CustomTabs
				defaultValue={location ?? "usersActivity"}
				entries={[
					{
						node: <UsersActivity />,
						value: "usersActivity",
						label: "statistics.users_activity",
					},
				]}
			/>
		</Section>
	);
};

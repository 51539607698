import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '../../Button'
import { Modal } from '../../Modal'
import { Subtitle } from '../../Typography'
import TableButton from '../TableButton'

type Props = {
  onDelete?: () => void
  onEdit?: () => void
  children?: React.ReactNode
}

const Actions: React.FC<Props> = ({ onDelete, onEdit, children }) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  return (
    <Container>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <ConfirmContainer>
            <Subtitle color="black" text="table.delete_item_confirmation" />
            <ButtonsContainer>
              <Button color="secondary" outline text="actions.cancel" onClick={()=> setOpenModal(false)}/>
               <Button color="primary" text="actions.confirm" onClick={()=> {onDelete && onDelete(); setOpenModal(false);}} /> 
            </ButtonsContainer>
          </ConfirmContainer>
        </Modal>
      )}
      {onDelete && (
        <TableButton
          iconName="trash"
          buttonColor="danger"
          onClick={() => setOpenModal(true)}
          suggestion={t('actions.delete')}
        />
      )}
      {onEdit && (
        <TableButton
          iconName="information-circle-outline"
          buttonColor="primary"
          onClick={onEdit}
          suggestion={t('actions.edit')}
        />
      )}
      {children}
    </Container>
  )
}

export default Actions

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  > * {
    margin-right: 10px;
  }
`

const ConfirmContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  > * {
    width: 120px;
  }
`

export enum TEMPERAMENTS {
    CALM = "CALM",
    FURIUS = "FURIUS",
    PLACID = "PLACID",
    NERVOUS = "NERVOUS",
    SCHIZZOFRENIC = "SCHIZZOFRENIC",
    AGGRESSIVE = "AGGRESSIVE",
    TIMID = "TIMID",
    CONFIDENT = "CONFIDENT",
    STABLE = "STABLE",
}

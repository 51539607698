import Table from "../../components/system/Table";
import Paginate from "../../components/system/Table/Paginate";
import { Flex } from "../../components/system/Table/Flex";
import React, { useState } from "react";
import { Column, Row } from "react-table";
import { OrderDirection } from "../../utils/types";
import { useTranslation } from "react-i18next";
import CellLink from "../../components/system/Table/CellLink";
import Actions from "../../components/system/Table/Actions";
import { useGetPaginatedPetsQuery } from "./operations/__generated__/getPaginatedPets.generated";
import { ROUTES } from "../../utils";

import { Text } from "../../components/system/Typography";
import OrderableHeaderCell from "../../components/system/Table/OrderableHeaderCell";
import toast from "react-hot-toast";
import FilterBar from "../../components/system/Table/FilterBar";
import { Maybe, Pagination } from "../../types";
import {  SimplePetFragment } from "./operations/__generated__/list-pets.generated";
import dayjs from "dayjs";
import { Section } from "../../components/common/Section";
import { useDeletePetMutation } from "./operations/__generated__/delete-pet.generated";

export const Pets = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [text, setText] = useState<string>("");
  const [data, setData] =
    useState<{items: Maybe<SimplePetFragment>[], pagination: Pick<Pagination, 'page_size' | 'current_page' |'total_items' | 'total_pages' >}>();
  const [ordering, setOrdering] = useState<{
    order_by?: string;
    order_direction?: OrderDirection;
  }>({});

  const [deletePet, {loading: deleteLoading}] = useDeletePetMutation({
    onCompleted: ()=> {refetch()}
  })

  const { loading, refetch } = useGetPaginatedPetsQuery({
    variables: {
      search: {
        page: page - 1,
        page_size: pageSize,
        order_by: ordering.order_by,
        order_direction: ordering.order_direction,
        ...( text.length> 0?{filters: { search : {value : text , fields: []}  } } : {})
      },
    },
    onCompleted: ({ listPets }) => {
      if (listPets.success && listPets.items && listPets.pagination) {
        setData({ items: listPets.items, pagination: listPets.pagination });
      }
    },
    onError: (err) => {
      toast.error(t("errors.fetch"));
    },
  });

  const columns = React.useMemo<Column<SimplePetFragment & { actions: any }>[]>(
    () => [
      {
        Header: ({ column: { id } }) => (
          <OrderableHeaderCell
            ntText={t(`pets.${id}`)}
            ordering={ordering}
            orderBy={id}
            onClick={(order_by, order_direction) => {
              setOrdering({ order_by, order_direction });
            }}
          />
        ),
        accessor: "name",
        Cell: ({ row, value }) => (
          <CellLink to={`${ROUTES.PETS}/${row.original.id}`} ntText={value} />
        ),
      },
      {
        Header: ({ column: { id } }) => (
          <OrderableHeaderCell
            ntText={t(`pets.${id}`)}
            ordering={ordering}
            orderBy={id}
            onClick={(order_by, order_direction) => {
              setOrdering({ order_by, order_direction });
            }}
          />
        ),
        accessor: "weight_kg",
        Cell: ({ row, value }) => (
          <CellLink to={`${ROUTES.PETS}/${row.original.id}`} ntText={`${value?? 'non definito'}`} />
        ),
      },
      {
        Header: ({ column: { id } }) => (
          <OrderableHeaderCell
            ntText={t(`pets.${id}`)}
            ordering={ordering}
            orderBy={id}
            onClick={(order_by, order_direction) => {
              setOrdering({ order_by, order_direction });
            }}
          />
        ),
        id: "family",
        Cell: ({ row  } : {row : Row<any>}) => (
          <CellLink to={`${ROUTES.PETS}/${row.original.id}`} ntText={`${row.original.body.family ?? 'non definito'}`} />
        ),
      },
      {
        Header: ({ column: { id } }) => (
          <OrderableHeaderCell
            ntText={t(`pets.${id}`)}
            ordering={ordering}
            orderBy={id}
            onClick={(order_by, order_direction) => {
              setOrdering({ order_by, order_direction });
            }}
          />
        ),
        id: "breed",
        Cell: ({ row  } : {row : Row<any>}) => (
          <CellLink to={`${ROUTES.PETS}/${row.original.id}`} ntText={`${row.original.body.breed ?t(`pets.breeds.${row.original.body.breed.toLowerCase()}`,row.original.body.breed.toLowerCase() )  : 'non definito'}`} />
        ),
      },
      {
        Header: ({ column: { id } }) => (
            <OrderableHeaderCell
            ntText={t(`pets.years`)}
            ordering={ordering}
            orderBy={id}
            onClick={(order_by, order_direction) => {
              setOrdering({ order_by, order_direction });
            }}
          />
        ),
        Cell: ({ row, value }) => (
          <CellLink
            to={`${ROUTES.PETS}/${row.original.id}`}
            ntText={`${dayjs().diff(value, "year")}`}
          />
        ),
        accessor: "birthday",
        width: 100,
      },

      {
        Header: () => (
          <Text color="black" weight={600}>
            {t("actions.actions")}
          </Text>
        ),
        Cell: ({ row }: { row: Row<any> }) => (
          <Actions onDelete={() => deletePet({variables : { id: row.original.id }})} />
        ),
        width: 70,
        id: "action",
      },
    ],
    [t, ordering]
  );

  return (
    <Section>
      <Flex
        justifyContent="center"
        flexDirection="column"
        width="100%"
      >
        {data && <FilterBar text={text} onSubmit={setText} />}
        <Table data={data} columns={columns} loading={loading || deleteLoading} />
        {data && !loading && !deleteLoading &&(
          <Paginate
            totalPages={data.pagination.total_pages ?? 1}
            limit={pageSize}
            pageSize={pageSize}
            includeAll
            currentItems={data.items.length}
            pageSizes={[10, 30, 50]}
            setPageSize={setPageSize}
            totalRows={data.pagination.total_items ?? 1}
            page={page}
            setPage={setPage}
          />
        )}
      </Flex>
    </Section>
  );
};

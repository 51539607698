import styled from "styled-components";
import { ColorName, useTheme } from "../../../components/system/themes";
import { Typography } from "../../../components/system/Typography";

type props = {
	selected?: Period;
	onClick: (period: Period) => void;
	backgroundColor?: ColorName;
	foreColor?: ColorName;
	overColor?: ColorName;
	selectedColor?: ColorName;
};

export enum Period {
	DAY = "day",
	WEEK = "week",
	MONTH = "month",
	YEAR = "year",
}

export const PerdioSelector: React.FC<props> = ({
	selected,
	onClick,
	backgroundColor = "white",
	foreColor = "black",
	overColor = "primaryDark",
	selectedColor = "primary",
}) => {
	const { theme } = useTheme();
	const periods: Period[] = [
		Period.DAY,
		Period.WEEK,
		Period.MONTH,
		Period.YEAR,
	];

	return (
		<Container>
			{periods.map((period) => (
				<PeriodContainer
					key={period}
					onClick={() => onClick(period)}
					className={period === selected ? "selected" : ""}
					slColor={theme[selectedColor]}
					frColor={theme[foreColor]}
					bgColor={theme[backgroundColor]}
					ovColor={theme[overColor]}
				>
					<Typography sizePx={16} text={`statistics.${period}`} />
				</PeriodContainer>
			))}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

type periodProps = {
	bgColor: string;
	frColor: string;
	slColor: string;
	ovColor: string;
};

const PeriodContainer = styled.div<periodProps>`
	margin-right: 20px;
	border: ${({ frColor }) => frColor};
	background-color: ${({ bgColor }) => bgColor};
	border-radius: 5px;
	cursor: pointer;
	padding: 5px 20px;
	box-sizing: border-box;
	> * {
		color: ${({ frColor }) => frColor};
	}
	&:hover {
		> * {
			color: ${({ ovColor }) => ovColor};
		}
	}
	&.selected {
		background-color: ${({ slColor }) => slColor};
		> * {
			color: #fff;
		}
	}
	&:last-child {
		margin-right: 0;
	}
`;

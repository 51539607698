import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { ColorName, useTheme } from "../system/themes";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
    data: number[];
    labels: string[];
    colors: ColorName[];
};

export const PieChart: React.FC<Props> = ({ data, labels, colors }) => {
    const themes = useTheme();
    const chartOptions = {
        plugins: {
            legend: {
                labels: {
                    color: themes.theme.black,
                },
            },
        },
    };
    const chartData = {
        datasets: [
            {
                data: data,
                borderColor: colors.map((color) => themes.theme[color]),
                backgroundColor: colors.map((color) => themes.theme[color]),
            },
        ],
        labels: labels,
    };
    return <Pie data={chartData} options={chartOptions} />;
};

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { I18NKey } from '../../i18n'
import { ColorName, useTheme } from './themes'

const styles: Record<string, { sizePx: number; weight: number; lineHeightPx: number }> = {
  headerTitle: { sizePx: 30, weight: 800, lineHeightPx: 48 },
  title: { sizePx: 30, weight: 700, lineHeightPx: 40 },
  subtitle: { sizePx: 18, weight: 700, lineHeightPx: 24 },
  text: { sizePx: 15, weight: 400, lineHeightPx: 20 },
  optionLabel: { sizePx: 13, weight: 600, lineHeightPx: 24 },
  label: { sizePx: 12, lineHeightPx: 16, weight: 600 },
  description: { sizePx: 12, lineHeightPx: 18, weight: 400 },
}

type Props = {
  type?: keyof typeof styles
  text?: I18NKey | ''
  // no-translated text
  ntText?: string
  asLabel?: boolean
  textVariables?: Record<string, any>
  color?: ColorName
  style?: Record<string, unknown>
  sizePx?: number
  weight?: number
  lineHeightPx?: number,
  children?: React.ReactNode
}

export const Typography: React.FC<Props> = ({
  type = 'title',
  text,
  ntText,
  textVariables,
  color = 'primary',
  asLabel,
  style,
  children,
  ...other
}) => {
  const themes = useTheme()
  const { t } = useTranslation()

  return (
    <StyledTypography
      as={asLabel ? 'label' : 'div'}
      type={type}
      color={themes.theme[color]}
      style={style}
      {...other}
    >
      {ntText}
      {text && t(text, textVariables)}
      {children}
    </StyledTypography>
  )
}

type HOCProps = Omit<Props, 'type'>
export const HeaderTitle: React.FC<HOCProps> = (props) => (
  <Typography {...props} type="headerTitle">
    {props.children}
  </Typography>
)
export const Title: React.FC<HOCProps> = (props) => (
  <Typography {...props} type="title">
    {props.children}
  </Typography>
)
export const Subtitle: React.FC<HOCProps> = (props) => (
  <Typography {...props} type="subtitle">
    {props.children}
  </Typography>
)
export const Text: React.FC<HOCProps> = (props) => (
  <Typography {...props} type="text">
    {props.children}
  </Typography>
)
export const OptionLabel: React.FC<HOCProps> = (props) => (
  <Typography color="gray" {...props} type="optionLabel">
    {props.children}
  </Typography>
)
export const Label: React.FC<HOCProps & { htmlFor?: string }> = (props) => (
  <Typography asLabel {...props} type="label">
    {props.children}
  </Typography>
)
export const Description: React.FC<HOCProps> = (props) => (
  <Typography {...props} type="description">
    {props.children}
  </Typography>
)

type StyledProps = {
  color: string
  type: keyof typeof styles
  sizePx?: number
  weight?: number
  lineHeightPx?: number
}
const StyledTypography = styled.div<StyledProps>`
  display: inline-block;
  font-size: ${(props) => props.sizePx ?? styles[props.type].sizePx}px;
  font-weight: ${(props) => props.weight ?? styles[props.type].weight};
  line-height: ${(props) =>
    `${props.lineHeightPx}px `??
    (styles[props.type].lineHeightPx ? styles[props.type].lineHeightPx + 'px' : 'normal')};
  color: ${(props) => props.color};
`

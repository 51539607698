import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteOwnershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOwnershipMutation = (
  { __typename?: 'Mutation' }
  & { deleteOwnership: (
    { __typename?: 'DeleteResult' }
    & Pick<Types.DeleteResult, 'success' | 'id'>
    & { error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'code' | 'extra' | 'message'>
    )> }
  ) }
);


export const DeleteOwnershipDocument = gql`
    mutation deleteOwnership($id: ID!) {
  deleteOwnership(id: $id) {
    success
    id
    error {
      code
      extra
      message
    }
  }
}
    `;
export type DeleteOwnershipMutationFn = Apollo.MutationFunction<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>;

/**
 * __useDeleteOwnershipMutation__
 *
 * To run a mutation, you first call `useDeleteOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnershipMutation, { data, loading, error }] = useDeleteOwnershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>(DeleteOwnershipDocument, options);
      }
export type DeleteOwnershipMutationHookResult = ReturnType<typeof useDeleteOwnershipMutation>;
export type DeleteOwnershipMutationResult = Apollo.MutationResult<DeleteOwnershipMutation>;
export type DeleteOwnershipMutationOptions = Apollo.BaseMutationOptions<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>;
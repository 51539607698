import Table from "../../../components/system/Table";
import Paginate from "../../../components/system/Table/Paginate";
import { Flex } from "../../../components/system/Table/Flex";
import { ReactElement, useState, useMemo } from "react";
import { Column, Row } from "react-table";
import { useTranslation } from "react-i18next";
import CellLink from "../../../components/system/Table/CellLink";
import { Text } from "../../../components/system/Typography";
import { useParams } from "react-router-dom";
import { Tag } from "../../../components/system/Tag";
import { CustodyLevel, Ownership } from "../../../types";
import Actions from "../../../components/system/Table/Actions";
import { roleColors } from "../../../utils/costants/tagColors";
import { ROUTES } from "../../../utils";
import { useGetPetOwnershipQuery } from "../operations/__generated__/getPetOwnerships.generated";
import toast from "react-hot-toast";
import { PetOwnershipsFragment } from "../operations/__generated__/pet-ownerships.generated";
import { Subtitle } from "../../../components/system/Typography";
import styled from "styled-components";
import { useDeletePetOwnershipMutation } from "../operations/__generated__/delete-pet-ownsership.generated";

export const PetSubOwners = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [subOwnerData, setSubOwnerData] = useState<PetOwnershipsFragment>();

  const [owPage, setOwPage] = useState(1);
  const [owPageSize, setOwPageSize] = useState(5);
  
  const [deleteOwnership, {loading: deleteLoading}] = useDeletePetOwnershipMutation({
    onCompleted: (data)=> {ownedPets.refetch()}
  })

  const ownedPets = useGetPetOwnershipQuery({
    variables: {
      id: id!,
      commonSearch: {
        page: owPage - 1,
        page_size: owPageSize,
        filters: {
            lists: [
              {
                key: "custody_level",
                value: [CustodyLevel.PetSitter, CustodyLevel.SubOwner],
              },
            ],
          },
      },
    },
    onCompleted: ({ getPet }) => {
      if (getPet.error) {
        toast.error(t("errors.fetch"));
        return;
      }
      if (getPet.success && getPet.pet && getPet.pet.ownerships) {
        setSubOwnerData(getPet.pet.ownerships);
      }
    },
    onError: () => {
      toast.error(t("errors.fetch"));
      return;
    },
  });

  const { t } = useTranslation();

 

  const columns = useMemo<Column<Omit<Ownership, "pet"> & { actions: any }>[]>(
    () => [
      {
        Header: () => <Text color="black" weight={600} text="users.first_name" />,
        accessor: (data) => data.user.first_name,
        Cell: ({
          row,
          value,
        }: {
          row: Row<Omit<Ownership, "pet"> & { actions: any }>;
          value: string;
        }) => (
          <CellLink
            to={`${ROUTES.USERS}/${row.original.user.id}`}
            ntText={value}
          />
        ),
        id: "first_name",
      },
      {
        Header: () => <Text color="black" weight={600} text="users.last_name" />,
        accessor: (data) => data.user.last_name,
        Cell: ({
          row,
          value,
        }: {
          row: Row<Omit<Ownership, "pet"> & { actions: any }>;
          value: string;
        }) => (
          <CellLink
            to={`${ROUTES.USERS}/${row.original.user.id}`}
            ntText={value}
          />
        ),
        id: "last_name",
      },
      {
        Header: () =><Text color="black" weight={600} text="users.email" />,
        accessor: (data) => data.user.email,
        Cell: ({
          row,
          value,
        }: {
          row: Row<Omit<Ownership, "pet"> & { actions: any }>;
          value: string;
        }) => (
          <CellLink
            to={`${ROUTES.USERS}/${row.original.user.id}`}
            ntText={value}
          />
        ),
        id: "email",
      },
      {
        Header: () => <Text color="black" weight={600} text="pets.ownerships_type" />,
        accessor: "custody_level",
        Cell: ({ row, value }) => (
          <CellLink to={`${ROUTES.USERS}/${row.original.user.id}`}>
            <Tag
              color={roleColors[value as CustodyLevel]}
              text={t(`pets.roles.${value}`).toLowerCase()}
            />
          </CellLink>
        ),
      },
      {
        Header: () => <Text color="black" weight={600} text="actions.actions" />,
        id: "actions",
        Cell: ({
          row,
        }: {
          row: Row<Omit<Ownership, "pet"> & { actions: any }>;
        }) => (
          <Actions onDelete={() => deleteOwnership({variables :{ id: row.original.id }})} />
        ),
      },
    ],
    [t, deleteOwnership]
  );

  return (
    <Container>
      <Flex
        justifyContent="center"
        flexDirection="column"
        padding="30px 0 40px 0"
      >
        <Subtitle color={"black"} text="pets.sub_owners" style={{ marginBottom: "20px" }} />
        {subOwnerData && (
          <>
            <Table
              data={subOwnerData}
              columns={columns}
              loading={ownedPets.loading || deleteLoading}
            />

            <Paginate
              totalPages={subOwnerData.pagination.total_pages || 0}
              limit={subOwnerData.pagination.page_size || 0}
              pageSize={owPageSize}
              pageSizes={[2, 5, 10]}
              currentItems={subOwnerData.items.length}
              includeAll
              setPageSize={setOwPageSize}
              totalRows={subOwnerData.pagination.total_items || 0}
              page={owPage}
              setPage={setOwPage}
            />
          </>
        )}
      </Flex>

      </Container>
  );
};


const Container = styled.div`
  width:100%;
  height: 100%;

`
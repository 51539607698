import styled from "styled-components";
import { Title } from "../../../components/system/Typography";
import { LineChart } from "../../../components/statistics/LineChart";
import { PerdioSelector } from "../components";
import { Period } from "../components";
import { useEffect,  useState } from "react";
import dayjs from "dayjs";
import {
    useGetGroupedStatsQuery,
} from "../operations/__generated__/getStatistics.generated";
import { Statistics } from "../../../types";
import toast from "react-hot-toast";
import { Loader } from "../../../components/common/Loader";
import { InfoCard } from "../../../components/statistics/InfoCard";
import _ from "lodash";
import { BarChart } from "../../../components/statistics/BarChart";

export const UsersActivity: React.FC = () => {
    
    const [selected, setSelected] = useState(Period.YEAR);
    const [dateFrom, setDateFrom] = useState(dayjs().toISOString());
    const [group, setGroup] = useState<"day" | "week" | "month">("month");
    const dateTo = dayjs().toISOString();

    const [data, setData] = useState<Statistics>();

    useEffect(() => {
        console.log("selectedCHanged");
        switch (selected) {
            case Period.DAY:
                setDateFrom(dayjs().startOf("day").toISOString());
                setGroup("day");
                break;
            case Period.WEEK:
                setGroup("day");
                if (dayjs().day() <= 1) {
                    setDateFrom(dayjs().subtract(1, "week").toISOString());
                    break;
                }
                setDateFrom(dayjs().startOf("week").toISOString());
                break;
            case Period.MONTH:
                setGroup("week");
                if (dayjs().date() <= 7) {
                    setDateFrom(
                        dayjs()
                            .subtract(1, "month")
                            .startOf("month")
                            .toISOString()
                    );
                    break;
                }
                setDateFrom(dayjs().startOf("month").toISOString());
                break;
            case Period.YEAR:
                setGroup("month");
                if (dayjs().get("month") < 1) {
                    setDateFrom(
                        dayjs()
                            .subtract(1, "year")
                            .startOf("year")
                            .toISOString()
                    );
                    break;
                }
                setDateFrom(dayjs().startOf("year").toISOString());
                break;
        }
    }, [selected]);

    useGetGroupedStatsQuery({
        variables: { dateFrom, dateTo, group },
        onCompleted: ({ getGroupedStatistics }) => {
            if (!getGroupedStatistics || !getGroupedStatistics.statistics) {
                toast.error("errors.general");
                return;
            }
            setData(getGroupedStatistics.statistics);
        },
    });

    return (
        <Container>
                <Title text="statistics.preview" color="black" />
                <PerdioSelector
                    onClick={(period: Period) => {
                        setSelected(period);
                    }}
                    selected={selected}
                    />
                {!data && <Loader size="large" />}
                {data && (<BodyContainer>   
                <CardContainer>
                    <InfoCard label="statistics.max_peak" value={_.max(data.active_users_max)!}  desc="statistics.max_peak_desc" />
                    <InfoCard label="statistics.min_peak" value={_.min(data.active_users_min)!} desc="statistics.min_peak_desc" />
                     
                </CardContainer>
                    <GraphsContainer>
                
                    {selected !== Period.DAY 
                     ? <LineChart
                            colors={["primary", "secondary", "tertiary", "quaternary"]}
                            labels={data.labels.map((label) =>
                                dayjs(label).format("MM DD")
                            )}
                            datasets={[
                                {
                                    data: data.active_users_mean,
                                    
                                    label: 'statistics.mean_users'
                                },
                                {
                                    data: data.active_users_max,
                                    
                                    label: 'statistics.max_users'
                                },
                                {
                                    data: data.active_users_min,
                                    
                                    label: 'statistics.min_users'
                                },
                            ]}
                        />
                    : <BarChart 
                    colors={["primary", "secondary", "tertiary", "quaternary"]}
                    labels={data.labels.map((label) =>
                        dayjs(label).format("MM DD")
                    )}
                    datasets={[
                        {
                            data: data.active_users_mean,
                            
                            label: 'statistics.mean_users'
                        },
                        {
                            data: data.active_users_max,
                            
                            label: 'statistics.max_users'
                        },
                        {
                            data: data.active_users_min,
                            
                            label: 'statistics.min_users'
                        },
                    ]}
                    />
                    }
            </GraphsContainer>
            </BodyContainer>
                )}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const BodyContainer = styled.div`
    width:100%;
    display: flex;
    flex-flow: nowrap ;

`

const CardContainer = styled.div`
    width:40%;
    padding-right: 20px;
    box-sizing: border-box;
    > * {
        margin-bottom: 20px;
    }
`
const GraphsContainer = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    .loader {
        align-self: center;
    }
    >* {
        margin-bottom: 25px;
    }
`;

import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemesContext, ColorName } from '../../../components/system/themes'
import { useFormContext, RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Size = 'small' | 'medium' | 'large'
export type InputProps = {
    name: string
    type?: 'text' | 'email' | 'password' | 'date' | 'number'
    min?: number
    max?: number
    autocomplete?: string
    size?: Size
    label?: string
    placeholder?: string
    description?: string
    errorText?: string
    valid?: boolean
    disabled?: boolean
    required?: boolean
    missing?: boolean;
    outlineColor?: ColorName
    onFocus?: () => void
    onBlur?: () => void

    value?: string
    translate?: boolean
    rows?: number
    columns?: number
    rightWidget?: React.ReactNode
    disabledBackgroundColor?: ColorName
    style?: Record<string, unknown>
    className?: string
    registerOptions?: RegisterOptions
}

const noop = (...s: any[]) => ({})
export function TradInput({
    name,
    type = 'text',
    autocomplete,
    size = 'large',
    label,
    translate = true,
    placeholder = label,
    outlineColor = 'primary',
    description,
    missing = false,

    valid = true,
    disabled = false,
    required = false,
    min,
    max,
    onFocus = noop,
    onBlur = noop,

    value,
    rightWidget,
    disabledBackgroundColor = 'grayLight',
    style,
    className,
    registerOptions,
}: InputProps) {
    const themes = useContext(ThemesContext)
    const { t } = useTranslation()
    const { register } = useFormContext()

    let realLabel = label !== '' ? (translate ? t(label ?? name) : label ?? name) : ''
    let realPlaceholder =
        placeholder === '' && label !== ''
            ? undefined
            : placeholder
                ? translate
                    ? t(placeholder)
                    : placeholder
                : `${t('insert_placeholder_infix')} ${translate ? t(name) : name}`
    if (required) {
        realLabel += ' *'
        realPlaceholder += ' *'
    }
    return (
        <Container style={style} className={className}>
            {realLabel !== '' && (
                <Label htmlFor={name} color={themes.theme['black']}>
                    {realLabel}
                </Label>
            )}
            <StyledInputContainer
                padding={size}
                focusColor={themes.theme[outlineColor]}
                textColor={themes.theme['blackDark']}
                blurredColor={themes.theme['grayDark']}
                errorColor={valid ? undefined : themes.theme['danger']}
                disabled={disabled}
                backgroundColor={themes.theme['pureWhite']}
                disabledBackgroundColor={themes.theme[disabledBackgroundColor]}
            >
                <StyledInput
                    {...{ name, type, onFocus, onBlur, disabled }}
                    placeholder={realPlaceholder}
                    size={1}
                    min={min}
                    max={max}
                    autoComplete={autocomplete}
                    value={value}
                    padding={size}
                    focusColor={themes.theme[outlineColor]}
                    textColor={themes.theme['blackDark']}
                    blurredColor={themes.theme['grayDark']}
                    errorColor={valid && !missing ? undefined : themes.theme['danger']}
                    backgroundColor={themes.theme['pureWhite']}
                    disabledBackgroundColor={themes.theme[disabledBackgroundColor]}
                    {...register(name, { required, ...registerOptions })}
                />
                {rightWidget}
            </StyledInputContainer>

        </Container>
    )
}

export const Container = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`

type Padding = { [key in Size]: { x: number; y: number } }
const paddings: Padding = {
    small: { x: 10, y: 2 },
    medium: { x: 12, y: 5 },
    large: { x: 16, y: 8 },
}
type StyledProps = {
    textColor: string
    blurredColor: string
    focusColor: string
    padding: Size
    backgroundColor: string
    disabledBackgroundColor: string
    errorColor?: string
    disabled?: boolean
}
export const StyledInput = styled.input<StyledProps>`
  font-family: Karla;
  font-size: 13px;
  height: 43px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 400;
  color: ${(props) => (props.disabled ? props.blurredColor : props.textColor)};
  background-color: ${(props) =>
        props.disabled ? props.disabledBackgroundColor : props.backgroundColor};
  border: none;
  margin: 0;
  /* box-sizing: border-box; */
  padding: ${(props) => paddings[props.padding].y}px ${(props) => paddings[props.padding].x}px;
  /* border: ${(props) => paddings[props.padding].x}px ${(props) =>
        paddings[props.padding].y}px solid
    ${(props) => (props.disabled ? props.disabledBackgroundColor : props.backgroundColor)}; */
  outline: none;
  flex-grow: 2;
  ::-webkit-input-placeholder {
    color: ${(props) => props.blurredColor};
  }
  ::placeholder {
    color: ${(props) => props.errorColor ? props.errorColor : props.blurredColor};
  }
  :disabled {
    background-color: ${(props) => props.disabledBackgroundColor};
  }
`

export const StyledInputContainer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border: 0.5px solid ${(props) => props.errorColor || '#ccc'};
  margin: 0;
  width: 100%;
  max-width: 100%;
  /* //calc(100% - ${(props) => paddings[props.padding].x * 2}px); */
  border-radius: 4px;
  outline: none;
  background-color: ${(props) =>
        props.disabled ? props.disabledBackgroundColor : props.backgroundColor};
  :focus-within {
    border: 0.5px solid ${(props) => props.errorColor || props.focusColor};
  }
`

const StyledTextArea = styled.textarea<StyledProps>`
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => (props.disabled ? props.blurredColor : props.textColor)};
  border: 0.5px solid ${(props) => props.errorColor || '#ccc'};
  margin: 0;
  padding-left: ${(props) => paddings[props.padding].x}px;
  padding-right: ${(props) => paddings[props.padding].x}px;
  padding-top: ${(props) => paddings[props.padding].y}px;
  padding-bottom: ${(props) => paddings[props.padding].y}px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  background-color: ${(props) =>
        props.disabled ? props.disabledBackgroundColor : props.backgroundColor};
  :focus {
    border: 0.5px solid ${(props) => props.errorColor || props.focusColor};
  }
  :disabled {
    background-color: ${(props) => props.disabledBackgroundColor};
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: ${(props) => props.blurredColor};
  }
  ::placeholder {
    color: ${(props) => props.blurredColor};
  }
`

type StyledLabelProps = { color: string }
export const Label = styled.label<StyledLabelProps>`
  font-family: Karla;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0;
  padding: 0;
  line-height: 20px;
  margin-bottom: 5px;
  display: inline-block;
`

export const Description = styled.span<StyledLabelProps>`
  font-family: Karla;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.color};
  margin: 0;
  padding: 0;
  margin-top: 5px;
  display: inline-block;
`

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcons } from 'react-ion-icon'
import styled from 'styled-components'
import { I18NKey } from '../../i18n'
import { Loader } from '../common/Loader'
// import 'microtip/microtip.css'

import { ThemesContext, ColorName } from './themes'
import { Icon } from './Icon'

export type ButtonColorNames =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'gray'
  | 'grayDark'
  | 'white'

const buttonColors: { [key in ButtonColorNames]: { full: ColorName; disabled: ColorName } } = {
  primary: { full: 'primary', disabled: 'primaryLight' },
  secondary: { full: 'secondary', disabled: 'secondaryLight' },
  tertiary: { full: 'tertiary', disabled: 'tertiaryLight' },
  success: { full: 'success', disabled: 'grayLight' },
  warning: { full: 'warning', disabled: 'grayLight' },
  danger: { full: 'danger', disabled: 'grayLight' },
  gray: { full: 'gray', disabled: 'grayLight' },
  grayDark: { full: 'grayDark', disabled: 'grayLight' },
  white: { full: 'white', disabled: 'gray' },
}

type Size = 'icon' | 'small' | 'medium' | 'large'
type Props = {
  text?: I18NKey
  onClick?: (ev: React.MouseEvent) => void
  size?: Size
  radius?: number
  color?: ButtonColorNames
  outline?: boolean
  outlineSize?: string
  leftIcon?: IonIcons
  rightIcon?: IonIcons
  disabled?: boolean
  translate?: boolean
  loading?: boolean
  type?: 'button' | 'reset' | 'submit'
  tooltip?: string
  iconColor?: ColorName
  tooltipPosition?:
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'right'
  tooltipSize?: 'small' | 'medium' | 'large' | 'fit'
  style?: Record<string, unknown>
  className?: string
}

export const Button = ({
  text,
  onClick = () => ({}),
  size = 'medium',
  radius = 4,
  color = 'primary',
  outline = false,
  outlineSize = '3px',
  disabled = false,
  loading = false,
  translate = true,
  type,
  leftIcon,
  rightIcon,
  iconColor,
  tooltip,
  tooltipPosition = 'top',
  tooltipSize,
  style,
  className,
}: Props) => {
  const themes = useContext(ThemesContext)
  const { full, disabled: disabledColor } = buttonColors[color]
  const { t } = useTranslation()
  return (
    <>
      {!loading 
      ?<StyledButton
        color={themes.theme[loading ? 'gray' : disabled ? disabledColor : full]}
        {...{ size, radius, outline, outlineSize, disabled, type }}
        onClick={disabled ? () => ({}) : onClick}
        style={style}
        className={className}
        aria-label={tooltip}
        data-microtip-position={tooltip ? tooltipPosition : undefined}
        data-microtip-size={tooltip ? tooltipSize : undefined}
        role={tooltip ? 'tooltip' : undefined}
        >
        {leftIcon && <Icon name={leftIcon} size="22px" color={iconColor}/>}
        <span
          style={{
            marginLeft: leftIcon && text ? '7px' : 0,
            marginRight: rightIcon && text ? '7px' : 0,
            transition: 'margin 0.5s ease',
          }}
          >
          {text ? (translate ? t(text) : text) : undefined}
        </span>
        {rightIcon && <Icon name={rightIcon} size="22px" color={iconColor} />}
      </StyledButton>
      : 
       <Loader size="small"/>}
    </>
  )
}

type Padding = { [key in Size]: { x: number; y: number } }
const paddings: Padding = {
  icon: { x: 0, y: 0 },
  small: { x: 8, y: 7 },
  medium: { x: 10, y: 14 },
  large: { x: 20, y: 19 },
}
type StyledProps = {
  color: string
  size: Size
  radius: number
  outline: boolean
  outlineSize: string
  disabled: boolean
}
const StyledButton = styled.button<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.size !== 'icon' ? '100%' : '24px')};
  height: ${(props) => (props.size !== 'icon' ? 'auto' : '24px')};
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  border: ${(props) => (props.outline ? `${props.outlineSize} solid ${props.color};` : 'none')};
  background-color: ${(props) => (props.outline ? 'white' : props.color)};
  color: ${(props) => (props.outline ? props.color : 'white')};
  border-radius: ${(props) => props.radius}px;
  padding-left: ${(props) => paddings[props.size].x}px;
  padding-right: ${(props) => paddings[props.size].x}px;
  padding-top: ${(props) => paddings[props.size].y}px;
  padding-bottom: ${(props) => paddings[props.size].y}px;
  transition: opacity 0.3s ease, color 0.5s ease, background-color 0.2s ease;
  :hover {
    ${(props) => (props.disabled ? '' : 'opacity: 0.8')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

export enum SPORTS {
    PARKOUR = "PARKOUR",
    RACE = "RACE" ,
    AGILITY = "AGILITY",
    DISC ="DISC",
    DIVING = "DIVING",
    HERDING_TRIALS = "HERDING_TRIALS",
    LURE_COURSING = "LURE_COURSING",
    RALLY_OBEDIENCE = "RALLY_OBEDIENCE",
    TRACKING = "TRACKING",
}
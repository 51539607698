import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type PetTreatmentFragment = (
  { __typename?: 'Treatment' }
  & Pick<Types.Treatment, 'id' | 'date' | 'created_at' | 'name' | 'type'>
  & { booster?: Types.Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Types.Treatment, 'name' | 'date' | 'id'>
  )> }
);

export const PetTreatmentFragmentDoc = gql`
    fragment PetTreatment on Treatment {
  id
  date
  created_at
  name
  type
  booster {
    name
    date
    id
  }
}
    `;
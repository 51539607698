import * as Types from '../../../../types.js';

import { FullUserFragment } from './full-user.generated';
import { gql } from '@apollo/client';
import { FullUserFragmentDoc } from './full-user.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  data: Types.UserUpdate;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserResult' }
    & Pick<Types.UserResult, 'success'>
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & FullUserFragment
    )>, error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'code' | 'message'>
    )> }
  ) }
);


export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UserUpdate!) {
  updateUser(id: $id, data: $data) {
    user {
      ...FullUser
    }
    success
    error {
      code
      message
    }
  }
}
    ${FullUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
import { useTranslation } from 'react-i18next'
import { IonIcons } from 'react-ion-icon'
import styled from 'styled-components'

import { ColorName, useTheme } from './themes'
import { Icon } from './Icon'

type Props = {
  text: string
  color?: ColorName
  iconRight?: IonIcons
  style?: Record<string, unknown>
}

export const Tag = ({ text, color = 'primary', iconRight, style }: Props) => {
  const themes = useTheme()
  const { t } = useTranslation()

  return (
    <StyledTag color={themes.theme[color]} style={style}>
      <span
        style={{
          margin: 0,
          marginRight: iconRight && text ? '7px' : 0,
          padding: 0,
        }}
      >
        {t(text)}
      </span>
      {iconRight && <Icon color={themes.theme['white']} name={iconRight} />}
    </StyledTag>
  )
}

type StyledProps = { color: string }
const StyledTag = styled.span<StyledProps>`
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  border: none;
  background-color: ${(props) => props.color};
  color: white;
  white-space: nowrap;
  border-radius: 20px;
  padding: 6px 10px;
  text-align: center;
`

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InfoCard } from "../../components/statistics/InfoCard";
import { LineChart } from "../../components/statistics/LineChart";
import { PieChart } from "../../components/statistics/PieChart";
import { useTheme } from "../../components/system/themes";
import { DailyStats, Dashboard } from "../../types";
import { useGetDashboardQuery } from "./operations/__generated__/getDashboard.generated";
import { useGetRealTimeStatsQuery } from "./operations/__generated__/getRealTimeStats.generated";
import dayjs from "dayjs";
import { Title } from "../../components/system/Typography";
import { Section } from "../../components/common/Section";

export const DashboardPage = () => {
	const { t } = useTranslation();

	const themes = useTheme();

	const [realTimeStats, setRealTimeStat] = useState<DailyStats>();
	const [dashboard, setDashBoard] = useState<Dashboard>();

	const { refetch } = useGetRealTimeStatsQuery({
		onCompleted: ({ getRealTimeStatistic }) => {
			if (!getRealTimeStatistic.success) {
				toast.error(t("errors.fetch"));
				return;
			}
			if (getRealTimeStatistic.statistics) {
				setRealTimeStat(
					getRealTimeStatistic.statistics as unknown as DailyStats
				);
			}
		},
		onError: () => {
			toast.error(t("errors.fetch"));
		},
	});

	useEffect(() => {
		const timer = setInterval(() => {
			refetch();
		}, 10000);
		return () => clearInterval(timer);
	}, [refetch]);

	useGetDashboardQuery({
		onCompleted: ({ getDashboard }) => {
			if (!getDashboard.success) {
				toast.error(t("errors.fetch"));
				return;
			}
			if (getDashboard.dashboard) {
				setDashBoard(getDashboard.dashboard);
			}
		},
		onError: () => {
			toast.error(t("errors.fetch"));
		},
	});

	return (
		<Section>
			{dashboard && realTimeStats && (
				<Cards>
					<InfoCard
						label="statistics.all_users"
						desc="statistics.all_users_desc"
						compareValue={dashboard.all_users}
						value={realTimeStats.all_users}
					></InfoCard>
					<InfoCard
						label="statistics.active_users"
						desc="statistics.active_users_desc"
						compareValue={dashboard.active_users}
						value={realTimeStats.active_users}
					></InfoCard>
					<InfoCard
						label="statistics.active_users"
						desc="statistics.active_users_desc"
						compareValue={dashboard.active_users_percent}
						value={realTimeStats.active_users_percent}
						unit="%"
					></InfoCard>
					<InfoCard
						label="statistics.all_pets"
						desc="statistics.all_pets_desc"
						compareValue={dashboard.all_pets}
						value={realTimeStats.all_pets}
					></InfoCard>
				</Cards>
			)}
			{dashboard && realTimeStats && (
				<Charts>
					<ChartBox backgroundColor={themes.theme.white}>
						<Title
							color="black"
							text="statistics.daily_users_activity"
						/>
						<PieChart
							data={[
								dashboard.active_users_mean,
								dashboard.all_users -
									dashboard.active_users_mean,
							]}
							labels={[
								t("statistics.active_users_mean"),
								t("statistics.inactive_users"),
							]}
							colors={["primaryDark", "primary"]}
						/>
					</ChartBox>
					<ChartBox backgroundColor={themes.theme.white}>
						<Title
							color="black"
							text="statistics.monthly_users_activity"
						/>
						<LineChart
							datasets={[
								{
									data: [
										...dashboard.all_users_stats,
										undefined,
									],
									label: t("statistics.all_users"),
								},
								{
									data: [
										...dashboard.active_users_stats,
										undefined,
									],
									label: t("statistics.active_users"),
								},
								{
									data: [
										...dashboard.all_pet_stats,
										undefined,
									],
									label: t("statistics.all_pets"),
								},
								{
									data: [
										...dashboard.active_users_stats.map(
											(stat, i) =>
												i ===
												dashboard.active_users_stats
													.length -
													1
													? stat
													: undefined
										),
										realTimeStats.active_users,
									],
									label: t("statistics.current_active_users"),
								},
							]}
							labels={dashboard.labels
								.map((label) => dayjs(label).format("DD MMM"))
								.concat(t("statistics.now"))}
							colors={[
								"secondaryLight",
								"primaryDark",
								"quaternary",
								dashboard.active_users_stats[
									dashboard.active_users_stats.length - 1
								] < realTimeStats.active_users
									? "success"
									: "danger",
							]}
						/>
					</ChartBox>
				</Charts>
			)}
		</Section>
	);
};

const Cards = styled.div`
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;
	margin-bottom: 20px;
	> * {
		width: 20%;
		@media screen and (max-width: 1080px) {
			width: 45%;
			margin-bottom: 20px;
		}
		@media screen and (max-width: 488px) {
			width: 100%;
		}
	}
`;

const Charts = styled.div`
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 1080px) {
		flex-direction: column;
	}
`;

const ChartBox = styled.div<{ backgroundColor: string }>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	padding: 12px;
	box-sizing: border-box;
	width: 46.5%;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 2px 2px 2px 1px #0003;
	@media screen and (max-width: 1080px) {
		width: 100%;
		margin-bottom: 20px;
	}

	> * {
		margin-bottom: 20px;
		&:nth-child(2) {
			max-height: 400px;
			justify-self: center;
			margin-top: auto;
			margin-bottom: auto;
			@media screen and (max-width: 488px) {
				max-height: unset;
			}
		}
	}
`;

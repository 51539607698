import styled from "styled-components";
import { Button } from "../../components/system/Button";

import { Admin } from "../../utils/types";
// import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useForm } from "react-hook-form";
import { Input } from "../../components/system/Input";
import { useGetMeQuery } from "./operations/__generated__/me.generated";
import toast from "react-hot-toast";
import { useUpdateMeMutation } from "./operations/__generated__/updateMe.generated";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/common/Loader";

export const ProfilePage = () => {
  const { handleSubmit, register, reset } =
    useForm<Admin & { password: string; confirm_password: string }>();

  const { t } = useTranslation();
  const { loading: getMeLoading } = useGetMeQuery({
    onCompleted: ({ me }) => {
      if (me.success && me.user) {
        reset({
          email: me.user.email,
          first_name: me.user.first_name,
          last_name: me.user.last_name,
        });
        return;
      }
      return toast.error("errors.me");
    },
    onError: (err) => {
      return toast.error("errors.me");
    },
  });

  const [updateMe, { loading }] = useUpdateMeMutation({
    onCompleted: ({ updateMe }) => {
      toast.success(t("message.me_update"));
    },
    onError: () => {},
  });

  return (
    <Container
      onSubmit={handleSubmit((variables) => {
        updateMe({ variables: { data: variables } } as any);
      })}
    >
      {!getMeLoading && !loading && (
        <FormContainer>
          <Input
            name="email"
            label="profile.email"
            innerRef={register}
            size="small"
          />
          <Input
            name="first_name"
            label="profile.first_name"
            innerRef={register}
            size="small"
          />
          <Input
            name="last_name"
            label="profile.last_name"
            innerRef={register}
            size="small"
          />
        </FormContainer>
      )}
      {(getMeLoading || loading) && (
        <LoaderContainer>
          <Loader size="large" />
        </LoaderContainer>
      )}
      <div style={{ flexGrow: 1 }} />
      <FooterContainer>
        <Button
          text="profile.save"
          type="submit"
          color="secondary"
          style={{ flexGrow: 0, marginLeft: "auto", flexBasis: "10%" }}
        />
      </FooterContainer>
    </Container>
  );
};

const Container = styled.form`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`;

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display:flex;
  justify-content:center;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
`;

import styled from "styled-components";
import { I18NKey } from "../../i18n";
import { ColorName, useTheme } from "../system/themes";
import { Description, Label, Text, Title } from "../system/Typography";

type Props = {
    label: I18NKey;
    desc: I18NKey;
    value: number;
    unit?: String;
    bgColor?: ColorName;
    compareValue?: number;
};

export const InfoCard: React.FC<Props> = ({
    label,
    desc,
    compareValue,
    value,
    unit = "",
    bgColor = "white",
}) => {
    const themes = useTheme();
    const compareResult =  compareValue ?  (value - compareValue) : undefined;
    return (
        <Container backgroundColor={themes.theme[bgColor]}>
            <Label color="black" text={label} />
            <ValueContainer>
                <Title color="primaryDark" ntText={`${value}${unit}`} />
                {!([0, undefined, null].includes(compareResult) ) && <Text lineHeightPx={30} color={ compareResult!> 0? 'success' : "danger"} ntText={`${compareResult! > 0 ? '+' :''}${compareResult?.toFixed(2).replace(/[.,]00$/, "")}${unit}`} />}
            </ValueContainer>
            <Description color="black" text={desc} />
        </Container>
    );
};

const Container = styled.div<{ backgroundColor: string }>`
    border-radius: 4px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 2px 2px 2px 1px #0003;
`;

const ValueContainer = styled.div`
    display: flex;
    align-items: flex-end;
    > * {
        margin-right: 7px;
        margin-bottom: 5px;
    }
`;

import * as Types from '../../../../types.js';

import { FullPetFragment } from './full-pet.generated';
import { gql } from '@apollo/client';
import { FullPetFragmentDoc } from './full-pet.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPetQuery = (
  { __typename?: 'Query' }
  & { getPet: (
    { __typename?: 'PetResult' }
    & Pick<Types.PetResult, 'success'>
    & { pet?: Types.Maybe<(
      { __typename?: 'Pet' }
      & FullPetFragment
    )>, error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'code' | 'message'>
    )> }
  ) }
);


export const GetPetDocument = gql`
    query getPet($id: ID!) {
  getPet(id: $id) {
    pet {
      ...FullPet
    }
    success
    error {
      code
      message
    }
  }
}
    ${FullPetFragmentDoc}`;

/**
 * __useGetPetQuery__
 *
 * To run a query within a React component, call `useGetPetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPetQuery(baseOptions: Apollo.QueryHookOptions<GetPetQuery, GetPetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPetQuery, GetPetQueryVariables>(GetPetDocument, options);
      }
export function useGetPetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPetQuery, GetPetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPetQuery, GetPetQueryVariables>(GetPetDocument, options);
        }
export type GetPetQueryHookResult = ReturnType<typeof useGetPetQuery>;
export type GetPetLazyQueryHookResult = ReturnType<typeof useGetPetLazyQuery>;
export type GetPetQueryResult = Apollo.QueryResult<GetPetQuery, GetPetQueryVariables>;
import styled from 'styled-components'
import { useTheme } from './themes'
import { Icon } from './Icon'

type Props = {
  onClose: () => void
  children: React.ReactNode
}

export const Modal: React.FC<Props> = ({ onClose, children }) => {

  const {theme}= useTheme()

  return (
    <Container>
      <ModalBox bgColor={theme.pureWhite}>
        <CloseContainer>
          <IconHitBox onClick={onClose}>
            <Icon name="close" size="24px" color={theme['black']} />
          </IconHitBox>
        </CloseContainer>
        {children}
      </ModalBox>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 0 16px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 1002;
  left: 0;
  background-color: #1e1e1ecc;
`

const ModalBox = styled.div<{bgColor: string}>`
  background-color: ${({bgColor})=> bgColor};
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
`

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  height: 25px;
  justify-content: end;
`

const IconHitBox = styled.div`
  padding: 2px;
`

import * as Types from '../../../../types.js';

import { SimplePetFragment } from './list-pets.generated';
import { SimpleBodyFragment } from './simple-pet-body.generated';
import { gql } from '@apollo/client';
import { SimplePetFragmentDoc } from './list-pets.generated';
import { SimpleBodyFragmentDoc } from './simple-pet-body.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPaginatedPetsQueryVariables = Types.Exact<{
  search: Types.CommonSearch;
}>;


export type GetPaginatedPetsQuery = (
  { __typename?: 'Query' }
  & { listPets: (
    { __typename?: 'PaginatedPets' }
    & Pick<Types.PaginatedPets, 'success'>
    & { pagination: (
      { __typename?: 'Pagination' }
      & Pick<Types.Pagination, 'total_items' | 'total_pages' | 'current_page' | 'page_size'>
    ), error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'message' | 'code' | 'extra'>
    )>, items: Array<Types.Maybe<(
      { __typename?: 'Pet' }
      & { body: (
        { __typename?: 'PetBody' }
        & SimpleBodyFragment
      ) }
      & SimplePetFragment
    )>> }
  ) }
);


export const GetPaginatedPetsDocument = gql`
    query getPaginatedPets($search: CommonSearch!) {
  listPets(commonSearch: $search) {
    pagination {
      total_items
      total_pages
      current_page
      page_size
    }
    success
    error {
      message
      code
      extra
    }
    items {
      ...SimplePet
      body {
        ...SimpleBody
      }
    }
  }
}
    ${SimplePetFragmentDoc}
${SimpleBodyFragmentDoc}`;

/**
 * __useGetPaginatedPetsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedPetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedPetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedPetsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPaginatedPetsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedPetsQuery, GetPaginatedPetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedPetsQuery, GetPaginatedPetsQueryVariables>(GetPaginatedPetsDocument, options);
      }
export function useGetPaginatedPetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedPetsQuery, GetPaginatedPetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedPetsQuery, GetPaginatedPetsQueryVariables>(GetPaginatedPetsDocument, options);
        }
export type GetPaginatedPetsQueryHookResult = ReturnType<typeof useGetPaginatedPetsQuery>;
export type GetPaginatedPetsLazyQueryHookResult = ReturnType<typeof useGetPaginatedPetsLazyQuery>;
export type GetPaginatedPetsQueryResult = Apollo.QueryResult<GetPaginatedPetsQuery, GetPaginatedPetsQueryVariables>;
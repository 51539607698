import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { LoginPage } from "../modules/auth/Login";
import { ProfilePage } from "../modules/profile/Profile";
 import { storage } from "../data/storage";
import { MainLayout } from "../components/system/MainLayout";
import { ROUTES } from "../utils";
import { Users } from "../modules/users/Users";
import { UserDetail } from "../modules/users/UserDetail";
import { Pets } from "../modules/pets/Pets";
import { PetDetail } from "../modules/pets/PetDetail";
import { DashboardPage } from "../modules/dashboard/";
import { StatisticsPage } from "../modules/statistics";
import { EditTranslations } from "../modules/translations/EditTranslations";
const PrivateRoute = ({
  component: Component,
  header,
  oneOf,
}: {
  component: React.ElementType;
  header: React.ComponentProps<typeof MainLayout>;
  [key: string]: any;
}) => {
  return (
      storage.getToken() !== null ? (
        <MainLayout {...header}>
          <Component />
        </MainLayout>
      ) : (
        <Navigate to={ROUTES.LOGIN} />
      )
    
       
      
  );
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<LoginPage />}></Route>
        <Route path={ROUTES.PROFILE_PAGE}  element={<PrivateRoute  header={{title: 'profile.profile'}} component={()=><ProfilePage />} />}/>
        <Route path={ROUTES.DASHBOARD}  element={<PrivateRoute  header={{title: 'dashboard.dashboard'}} component={()=><DashboardPage />} />}/>
        <Route path={ROUTES.STATISTICS}  element={<PrivateRoute  header={{title: 'statistics.statistics'}} component={()=><StatisticsPage />} />}/>
        <Route path={ROUTES.USERS_DETAILS}  element={<PrivateRoute  header={{title: 'users.user_details', backLink: {to: ROUTES.USERS, text: 'users.users'} }} component={()=><UserDetail/>} />}/>
        <Route path={ROUTES.USERS}  element={<PrivateRoute  header={{title: 'users.users' }} component={()=><Users/>} />}/>
        <Route path={ROUTES.PETS_DETAILS}  element={<PrivateRoute  header={{title: 'pets.pets_detail' , backLink: {to : ROUTES.PETS, text: 'pets.pets'}}} component={()=><PetDetail/>} />}/>
        <Route path={ROUTES.PETS}  element={<PrivateRoute  header={{title: 'pets.pets' }} component={()=><Pets/>} />}/>
        <Route path={ROUTES.TRANSLATIONS}  element={<PrivateRoute  header={{title: 'translations.translations' }} component={()=><EditTranslations />} />}/>
        <Route path="/" element= {<Navigate to={ROUTES.DASHBOARD} />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

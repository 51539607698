import { Flex } from '../Flex'
import { Row } from 'react-table'
import styled from 'styled-components'
import { useTheme } from '../../themes'

interface Props {
  row: Row
  index: number
}

const RowComponent = ({ row, index }: Props) => {
  const themes = useTheme();
  return (
    <Tr hoverColor={themes.theme['grayLight']} color={themes.theme['white']} strippedColor={themes.theme['whiteDark']} {...row.getRowProps()} striped={!(index % 2)}>
      {row.cells.map((cell) => {
        return (
          <Td {...cell.getCellProps()}>
            <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
              {cell.render('Cell')}
            </Flex>
          </Td>
        )
      })}
    </Tr>
  )
}

export default RowComponent

interface TrProps {
  striped?: boolean
  strippedColor: string
  color: string
  hoverColor: string
}
export const Tr = styled.tr<TrProps>`
  background-color: ${({ striped, strippedColor, color }) => (striped ? strippedColor : color )};
  :hover:not(.header) {
    background-color: ${({hoverColor})=> hoverColor};
  }
`
export const Td = styled.td`
  padding: 15px;
`

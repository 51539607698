import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type FullPetFragment = (
  { __typename?: 'Pet' }
  & Pick<Types.Pet, 'id' | 'name' | 'birthday' | 'neutered' | 'gender' | 'weight_kg' | 'diet' | 'chip_code' | 'intollerance' | 'temperament' | 'disciplines'>
  & { ownerships?: Types.Maybe<(
    { __typename?: 'PaginatedOwnerships' }
    & { items: Array<Types.Maybe<(
      { __typename?: 'Ownership' }
      & Pick<Types.Ownership, 'id'>
      & { user: (
        { __typename?: 'User' }
        & Pick<Types.User, 'first_name' | 'last_name' | 'id' | 'email'>
      ) }
    )>> }
  )>, body: (
    { __typename?: 'PetBody' }
    & Pick<Types.PetBody, 'id' | 'breed' | 'family'>
    & { coat: (
      { __typename?: 'Coat' }
      & Pick<Types.Coat, 'id' | 'pattern' | 'colors'>
    ) }
  ) }
);

export const FullPetFragmentDoc = gql`
    fragment FullPet on Pet {
  id
  name
  birthday
  neutered
  gender
  weight_kg
  diet
  chip_code
  intollerance
  temperament
  disciplines
  ownerships(
    commonSearch: {filters: {fixed: [{key: "custody_level", value: "OWNER"}]}}
  ) {
    items {
      id
      user {
        first_name
        last_name
        id
        email
      }
    }
  }
  body {
    id
    breed
    family
    coat {
      id
      pattern
      colors
    }
  }
}
    `;
import React, { useContext } from "react";
export type Themes = {
    dark: { [key in ColorName]: string };
    light: { [key in ColorName]: string };
};

export type ColorName =
    | "trueWhite"
    | "trueBlack"
    | "pureWhite"
    | "pureBlack"
    | "black"
    | "blackDark"
    | "blackLight"
    | "white"
    | "whiteDark"
    | "whiteLight"
    | "primary"
    | "primaryDark"
    | "primaryLight"
    | "secondary"
    | "secondaryDark"
    | "secondaryLight"
    | "tertiary"
    | "tertiaryDark"
    | "tertiaryLight"
    | "quaternary"
    | "quaternaryDark"
    | "quaternarLight"
    | "gray"
    | "grayDark"
    | "grayLight"
    | "success"
    | "warning"
    | "danger"
    | "trasparent";

export const themes: Themes = {
    light: {
        trueWhite: "#FFFFFF",
        trueBlack: "#111111",
        pureWhite: "#FFFFFF",
        pureBlack: "#000000",
        black: "#2B2B2B",
        blackDark: "#1F1F1F",
        blackLight: "#3D3D3D",
        white: "#f2f2f2",
        whiteDark: "#EBEBEB",
        whiteLight: "#F8F8F8",
        primary: "#2A9D8F",
        primaryDark: "#15514A",
        primaryLight: "#6ED8CC",
        secondary: "#264653",
        secondaryDark: "#13242A",
        secondaryLight: "#73A9BF",
        tertiary: "#284B63",
        tertiaryDark: "#12212B",
        tertiaryLight: "#6EA2C4",
        quaternary: "#9d79bc",
        quaternaryDark: "#49305F",
        quaternarLight: "#CDBBDD",
        gray: "#BABABA",
        grayDark: "#707070",
        grayLight: "#E0E0E0",
        success: "#a1dd70",
        warning: "#fdd043",
        danger: "#ff5959",
        trasparent: "#00000000",
    },

    dark: {
        trueWhite: "#FFFFFF",
        trueBlack: "#111111",
        pureWhite: "#111111",
        pureBlack: "#FFFFFF",
        black: "#f2f2f2",
        blackDark: "#F8F8F8",
        blackLight: "#EBEBEB",
        white:  "#2A9D8F",
        whiteDark:  "#6ED8CC",
        whiteLight:  "#15514A",
        primary: "#BABABA",
        primaryDark: "#E0E0E0",
        primaryLight: "#707070",
        secondary: "#2B2B2B",
        secondaryDark: "#3D3D3D",
        secondaryLight: "#1F1F1F",
        tertiary: "#9D79BC",
        tertiaryDark: "#CDBBDD",
        tertiaryLight: "#49305F",
        quaternary: "#284B63",
        quaternaryDark: "#6EA2C4",
        quaternarLight: "#12212B",
        gray: "#264653",
        grayDark: "#73A9BF",
        grayLight: "#13242A",
        success: "#A1DD70",
        warning: "#FDD043",
        danger: "#FF5959",
        trasparent: "#00000000",
    },
};
export const ThemesContext = React.createContext({
    theme: themes.light,
    toggleTheme: () => {},
});

export const useTheme = () => {
    return useContext(ThemesContext);
};

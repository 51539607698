import { useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IonIcons } from "react-ion-icon";
import styled, { keyframes } from "styled-components";
import { I18NKey } from "../../i18n";
import { Icon } from "./Icon";
import { ColorName, useTheme } from "./themes";
import { Label } from "./Typography";
import _ from "lodash";
import useOnClickOutside from "../../hooks/useOnClickOutseide";

type Option = {
    value: any;
    label: string;
};

type props = {
    options: Option[];
    icon?: IonIcons;
    control: any;
    name: string;
    inverted?: boolean;
    label?: I18NKey;
    placeholder?: I18NKey;
    bgColor?: ColorName;
    fgColor?: ColorName;
    brdColor?: ColorName;
    hvColor?: ColorName;
    fcColor?: ColorName;
    itemsPerList?: number;
    opsColor?: ColorName;
};

export const Select: React.FC<props> = ({
    options,
    icon,
    label,
    name,
    placeholder,
    control,
    itemsPerList=5,
    inverted = false,
    fgColor = "black",
    bgColor = "whiteLight",
    brdColor = "gray",
    opsColor = "whiteDark",
    hvColor="white",
    fcColor= "primary",
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const [loaded, setLoaded] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [up, setUp] = useState(false);
    const themes = useTheme();
    const { t } = useTranslation();
    const foregroundColor = themes.theme[fgColor];
    const backgroundColor = themes.theme[bgColor];
    const borderColor = themes.theme[brdColor];
    const optionSelectedColor = themes.theme[opsColor];
    const hoverColor = themes.theme[hvColor]
    const focusColor= themes.theme[fcColor]
    //only porpouse to not initializae animation keyframe on loaded
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    useOnClickOutside(ref, () => setShowOptions(false));

    const openSelect = useCallback((e: any) => {
        setShowOptions((showOptions) => !showOptions);
        const items =
            optionsRef.current?.children &&
            optionsRef.current?.children.length < 6
                ? optionsRef.current?.children.length
                : 6;
        const itemsHeight = optionsRef.current?.children
            ? optionsRef.current?.children[0].clientHeight
            : 0;
        console.log(
            `${items} * ${itemsHeight} + ${e.clientY} > ${window.innerHeight}`,
            items * itemsHeight + e.clientY > window.innerHeight
        );
        setUp(items * itemsHeight + e.clientY > window.innerHeight);
    }, []);

    return (
        <Container ref={ref}>
            <Label ntText={t(label ? label : name)} color="black" sizePx={13} />
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                    <SelectContainer
                        color={foregroundColor}
                        inverted={inverted}
                        bgColor={backgroundColor }
                        
                        brdColor={showOptions ? focusColor :borderColor}
                    >
                        <LabelContainer
                            placeholderColor={themes.theme["gray"]}
                            onClick={openSelect}
                        >
                            {value ? (
                                <p>
                                    {
                                        _.find(
                                            options,
                                            (opt) => opt.value === value
                                        )?.label
                                    }
                                </p>
                            ) : (
                                <p className="placeholder">
                                    {placeholder ? t(placeholder) : name}
                                </p>
                            )}
                        </LabelContainer>
                        <Separetor color={showOptions ? focusColor :borderColor} />
                        <IconContainer
                            show={showOptions}
                            inverted={inverted}
                            loaded={loaded}
                            onClick={openSelect}
                        >
                            <Icon
                                name={icon ? icon : "caret-down-outline"}
                                color={showOptions ? focusColor :foregroundColor}
                            />
                        </IconContainer>
                        <OptionsContainer
                            ref={optionsRef}
                            className={`${up ? "up" : ""} ${
                                showOptions ? "open" : ""
                            }`}
                            maxHeight={ options.length > itemsPerList ? itemsPerList * 60 : options.length * 60}
                            color={borderColor}
                            bgColor={backgroundColor}
                        >
                            {options.map((option) => (
                                <OptionContainer
                                    key={option.value}
                                    bkgColor={
                                        option.value === value
                                            ? optionSelectedColor
                                            : undefined
                                    }
                                    hoverColor={hoverColor}
                                    onClick={() => {
                                        if (option.value === value) {
                                            onChange(null);
                                            return;
                                        }
                                        setShowOptions(false);
                                        onChange(option.value);
                                    }}
                                >
                                    <p>{option.label}</p>
                                </OptionContainer>
                            ))}
                        </OptionsContainer>
                    </SelectContainer>
                )}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    position: relative;
    z-index: auto;
    flex-direction: column;
    > * {
        &:first-child {
            line-height: 20px;
            margin-bottom: 5px;
        }
    }
`;
const SelectContainer = styled.div<{
    color: string;
    inverted: boolean;
    bgColor: string;
    brdColor: string;
}>`
    height: 48px;
    color: ${({ color }) => color};
    padding: ${({ inverted }) => (inverted ? "3px 20px 3px 3px" : "3px ")};

    display: flex;
    flex-flow: nowrap;
    flex-direction: ${({ inverted }) => (inverted ? "row-reverse" : "row")};
    align-items: center;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor};
    border: 1px solid ${({ brdColor }) => brdColor};
    > * {
        cursor: pointer;
    }
    &:focus{
        border-color: #aa0000
    }
`;

const LabelContainer = styled.div<{ placeholderColor: string }>`
    width: calc(100% - 41px);
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 100%;
    justify-content: flex-start;
    > .placeholder {
        color: ${({ placeholderColor }) => placeholderColor};
    }
`;

const IconContainer = styled.div<{
    show: boolean;
    inverted: boolean;
    loaded: boolean;
}>`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        animation-name: ${({ show, inverted }) =>
            show ? openOption(inverted) : closeOption(inverted)};
        animation-duration: ${({ loaded }) => (loaded ? ".5s" : "")};
        transform: rotate(${({ show }) => (show ? "180deg" : "0")});
    }
`;

const Separetor = styled.div<{ color: string }>`
    width: 1px;
    height: 90%;
    background-color: ${({ color }) => color};
`;

const OptionsContainer = styled.div<{
    color: string;
    bgColor: string;
    maxHeight : number;
}>`
    width: calc(100% - 40px);
    border-radius: 4px;
    border: 1px solid ${({ color }) => color};
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    padding-bottom: 0;
    top: calc(100% + 10px);
    overflow-x: hidden;
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
    background-color: ${({ bgColor }) => bgColor};
    transition: 1s max-height cubic-bezier(0, 0.75, 0, 0.96),
        0.4s opacity ease-in;
    &.open {
        max-height: ${({maxHeight})=> maxHeight}px;
        opacity: 1;
        overflow-y: scroll;
    }
    &.up {
        bottom: 60px;
        top: unset;
    }
`;

const OptionContainer = styled.div<{ bkgColor?: string, hoverColor: string }>`
    width: 100%;
    padding: 3px 10px;
    height : 60px;
    background-color: ${({ bkgColor }) => (bkgColor ? bkgColor : "inherit")};
    &:hover {
        background-color: ${({ hoverColor }) => (hoverColor ? hoverColor : "inherit")};

    }
`;

const openOption = (inverted: boolean) => keyframes`
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(${!inverted ? "-180deg" : "180deg"});
    }
`;
const closeOption = (inverted: boolean) => keyframes`
    0% {
        transform: rotate(${!inverted ? "-180deg" : "180deg"});
    }
    100% {
        transform: rotate(0deg);
    }
`;

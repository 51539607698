import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type SimplePetFragment = (
  { __typename?: 'Pet' }
  & Pick<Types.Pet, 'id' | 'name' | 'weight_kg' | 'birthday' | 'neutered' | 'chip_code' | 'gender'>
);

export const SimplePetFragmentDoc = gql`
    fragment SimplePet on Pet {
  id
  name
  weight_kg
  birthday
  neutered
  chip_code
  gender
}
    `;
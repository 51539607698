import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Select } from "../../../components/system/Select";
import { FullPetFragment } from "../operations/__generated__/full-pet.generated";
import { BODIES, BREEDS, DIETS, SPORTS, GENDERS, TEMPERAMENTS } from "../utils";
import { Input } from "../../../components/system/Input";
import { MultiSelect } from "../../../components/system/MultiSelect";
import { Button } from "../../../components/system/Button";
import { useEffect } from "react";
import { composeUniqueList } from "../../../components/system/utils/composeUniqueList";
import { Radio } from "../../../components/system/Radio";
import toast from "react-hot-toast";
import 'react-day-picker/dist/style.css';
import _ from "lodash";
import { useUpdatePetMutation } from "../operations/__generated__/updatePet.generated";
import { Label } from "../../../components/system/Typography";
import { ROUTES } from "../../../utils";
import { InternalLink } from "../../../components/system/InternalLink";
import { DayPicker } from "react-day-picker";
import { DateInput } from "../../../components/system/DateInput";

export const PetInfo: React.FC<{ data?: FullPetFragment }> = (data) => {
    const { id } = useParams<{ id: string }>();

    const { handleSubmit, register, control, reset } = useForm({
        mode: "onChange",
    });
    const { t } = useTranslation();
    const [updatePet] = useUpdatePetMutation({
        onCompleted: ({ updatePet: updatePetData }) => {
            if (updatePetData.error) {
                toast.error(t("errors.fetch"));
            }
            if (updatePetData.success && updatePetData.pet) {
                reset(updatePetData.pet);
                toast.success(t("message.success.pet_updated"));
            }
        },
        onError: (err) => {
            toast.error(t("errors.fetch"));
        },
    });
    useEffect(() => {
        if (data) {
            reset(data.data);
        }
    }, [data, reset]);


    return (
        <Container>
            <FieldContainer
                onSubmit={handleSubmit((values) => {
                    values.weight_kg = parseFloat(values.weight_kg);
                    updatePet({
                        variables: {
                            id: id!,
                            data: _.omit(values, [
                                "__typename",
                                "id",
                                "body.__typename",
                                "body.id",
                                "ownerships",
                                "body.coat.__typename",
                                "body.coat.id",
                            ]),
                        },
                    });
                })}
            >
                {data.data && (
                    <>
                        <Input
                            name="name"
                            size="small"
                            placeholder="pets.name"
                            label="pets.name"
                            innerRef={register}
                        />
                        {data.data.ownerships &&
                            data.data.ownerships.items.length > 0 && (
                                    <InternalLink
                                        label="pets.owner"
                                        to={`${ROUTES.USERS}/${
                                            data.data.ownerships.items[0]!.user.id
                                        }`}
                                        ntText={`${
                                            data.data.ownerships.items[0]!.user
                                            .first_name
                                        } ${
                                            data.data.ownerships.items[0]!.user
                                            .last_name
                                        }`}
                                        color="primaryDark"
                                        iconRight="enter-outline"
                                    />
                            )}
                        <Select
                            options={composeUniqueList(
                                Object.entries(GENDERS),
                                [],
                                "pets.genders"
                            )}
                            name="gender"
                            label="pets.gender"
                            placeholder="pets.gender"
                            control={control}
                            bgColor="pureWhite"
                        />

                        <Select
                            options={composeUniqueList(
                                Object.entries(BODIES),
                                data.data.body.family
                                    ? [data.data.body.family]
                                    : [],
                                "pets.families"
                            )}
                            name="body.family"
                            label="pets.family"
                            placeholder="pets.family"
                            control={control}
                            bgColor="pureWhite"
                        />
                        <Select
                            options={composeUniqueList(
                                Object.entries(BREEDS),
                                data.data.body.breed
                                    ? [data.data.body.breed]
                                    : [],
                                "pets.breeds"
                            )}
                            label="pets.breed"
                            placeholder="pets.breed"
                            name="body.breed"
                            control={control}
                            bgColor="pureWhite"
                        />

                        <MultiSelect
                            options={composeUniqueList(
                                Object.entries(DIETS),
                                data.data.diet ? data.data.diet : [],
                                "pets.diets"
                            )}
                            name="diet"
                            label="pets.diet"
                            placeholder="pets.diet"
                            control={control}
                            bgColor="pureWhite"
                        />
                        <MultiSelect
                            options={composeUniqueList(
                                Object.entries(SPORTS),
                                data.data.disciplines
                                ? data.data.disciplines
                                : [],
                                "pets.sports"
                                )}
                            label="pets.sport"
                            placeholder="pets.sport"
                            name="disciplines"
                            control={control}
                            bgColor="pureWhite"
                        />
                        <Select
                            options={composeUniqueList(
                                Object.entries(TEMPERAMENTS),
                                data.data.temperament
                                    ? [data.data.temperament]
                                    : [],
                                "pets.temperaments"
                            )}
                            label="pets.temperament"
                            placeholder="pets.temperament"
                            name="temperament"
                            control={control}
                            bgColor="pureWhite"
                        />
                        <Input
                            name="weight_kg"
                            placeholder="pets.weight_kg"
                            label="pets.weight_kg"
                            size="small"
                            type="number"
                            min={0}
                            max={60}
                            innerRef={register}
                        />
                        <Radio
                            control={control}
                            options={[
                                {
                                    value: true,
                                    label: "pets.neutered",
                                },
                                { value: false, label: "pets.not_neutered" },
                            ]}
                            selectedColor="primaryDark"
                            radioColor="grayDark"
                            radioIcon="checkmark-circle"
                            name="neutered"
                            label="pets.sterilization"
                        />
                        <DateInput name="birthday" control = {control} inputBackgroundColor="pureWhite" calendarBackgroundColor="pureWhite"  />
                    </>
                )}
                <FooterContainer>
                    <Button
                        type="submit"
                        text="users.save"
                        color="secondary"
                        style={{
                            flexGrow: 0,
                            marginLeft: "auto",
                            flexBasis: "20%",
                        }}
                    />
                </FooterContainer>
            </FieldContainer>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
`;

const FieldContainer = styled.form`
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    > * {
        width: 30%;
        margin-bottom: 20px;
        @media screen and (max-width: 1279px){
            width: 45%;
        }
        @media screen and (max-width: 879px){
            width: 100%;
        }

    }
`;

const FooterContainer = styled.div`
    align-self: flex-end;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 40px 0 0 0;
`;

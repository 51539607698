import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type ListUserFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'email' | 'last_name' | 'first_name' | 'role' | 'created_at' | 'pets_owned' | 'pets_on_loan'>
);

export const ListUserFragmentDoc = gql`
    fragment ListUser on User {
  id
  email
  last_name
  first_name
  role
  created_at
  pets_owned
  pets_on_loan
}
    `;
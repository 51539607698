import * as Types from '../../../../types.js';

import { PetOwnershipsFragment } from './pet-ownerships.generated';
import { gql } from '@apollo/client';
import { PetOwnershipsFragmentDoc } from './pet-ownerships.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPetOwnershipQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  commonSearch: Types.CommonSearch;
}>;


export type GetPetOwnershipQuery = (
  { __typename?: 'Query' }
  & { getPet: (
    { __typename?: 'PetResult' }
    & Pick<Types.PetResult, 'success'>
    & { pet?: Types.Maybe<(
      { __typename?: 'Pet' }
      & { ownerships?: Types.Maybe<(
        { __typename?: 'PaginatedOwnerships' }
        & PetOwnershipsFragment
      )> }
    )>, error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'code' | 'message' | 'extra'>
    )> }
  ) }
);


export const GetPetOwnershipDocument = gql`
    query getPetOwnership($id: ID!, $commonSearch: CommonSearch!) {
  getPet(id: $id) {
    pet {
      ownerships(commonSearch: $commonSearch) {
        ...PetOwnerships
      }
    }
    success
    error {
      code
      message
      extra
    }
  }
}
    ${PetOwnershipsFragmentDoc}`;

/**
 * __useGetPetOwnershipQuery__
 *
 * To run a query within a React component, call `useGetPetOwnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetOwnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetOwnershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *      commonSearch: // value for 'commonSearch'
 *   },
 * });
 */
export function useGetPetOwnershipQuery(baseOptions: Apollo.QueryHookOptions<GetPetOwnershipQuery, GetPetOwnershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPetOwnershipQuery, GetPetOwnershipQueryVariables>(GetPetOwnershipDocument, options);
      }
export function useGetPetOwnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPetOwnershipQuery, GetPetOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPetOwnershipQuery, GetPetOwnershipQueryVariables>(GetPetOwnershipDocument, options);
        }
export type GetPetOwnershipQueryHookResult = ReturnType<typeof useGetPetOwnershipQuery>;
export type GetPetOwnershipLazyQueryHookResult = ReturnType<typeof useGetPetOwnershipLazyQuery>;
export type GetPetOwnershipQueryResult = Apollo.QueryResult<GetPetOwnershipQuery, GetPetOwnershipQueryVariables>;

import { storage } from './storage'
import _ from 'lodash'


class API {
    private request = async <T>(
        method: string,
        path: string,
        body?: {},
        params?: { [key: string]: string | string[] },
        useToken: boolean = true
    ) => {
        let uri = process.env.REACT_APP_BASE_FETCH_URL + path
        if (_.keys(params).length > 0) {
            uri +=
                '?' +
                _.map(params, (value, key) =>
                    Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
                ).join('&')
        }
        return fetch(uri, {
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers: this.getHeaders(useToken),
        }).then((res) => this.handleResponse<T>(res))
    }

    private handleResponse = <T>(response: Response) => {
        if (response.status !== 200) {
            console.error(`Error while sending request. Code: ${response.status}`)
        }

        return response.json().then((json: { data: T | null; error: null | { code: string } }) => {
            if (response.status !== 200) {
                throw new Error(json.error!.code || 'invalid_server_response')
            }
            return json.data
        })
    }

    private getHeaders = (useToken: boolean) => {
        const token = storage.getToken()
        return {
            ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
            Platform: 'console',
            'User-Language': navigator?.language,
            'Content-Type': 'application/json',
        }
    }

    private get = async <T>(
        path: string,
        params: { [key: string]: string | string[] },
        useToken: boolean = true
    ) => this.request<T>('GET', path, undefined, params, useToken)

    private delete = async <T>(
        path: string,
        params: { [key: string]: string },
        useToken: boolean = true
    ) => this.request<T>('DELETE', path, undefined, params, useToken)

    private post = async <T>(path: string, body: {}, useToken: boolean = true) =>
        this.request<T>('POST', path, body, undefined, useToken)

    private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
        this.request<T>('PUT', path, body, undefined, useToken)

    private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
        this.request<T>('PATCH', path, body, undefined, useToken)

    private cleanParams = (params: {
        [key: string]: string | number | null | undefined | string[]
    }) =>
        _(params)
            .pickBy((value) => value !== null && value !== undefined && value !== '')
            .mapValues((param) => {
                if (Array.isArray(param)) {
                    return param.map((p) => String(p))
                }
                return String(param)
            })
            .value()


    public getTranslations = () => this.get<any>('/translations', {})

    public saveTranslations = (translations: Record<string, any>) => this.put<any>("/translations/update", translations)


}


export const api = new API()

import React, { useEffect, useState, useCallback } from "react";
import { api } from "../../data/api";
import { useRequest } from "../../hooks/useRequest";
import { LanguagesFields } from "./components/LanguagesFields";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/system/Button";

interface FakeData {
	[key: string]: string | FakeData;
}

interface Result {
	_values: { key: string; missing?: string[] }[];
	[key: string]: any;
}

export const EditTranslations = React.memo(() => {
	const [request, { status, data }] = useRequest(api.getTranslations);
	const [update, {status: upèdateStatus}] = useRequest(api.saveTranslations)
	const [parsed, setParsed] = useState<Result>();
	const [languages, setLanguages] = useState<string[]>();
	const methods = useForm<FakeData>({ mode: "onSubmit" });
	const { t } = useTranslation();
	const generateResult = useCallback((fakeData: FakeData): Result => {
		const result: Result = { _values: [] };
		const languages = Object.keys(fakeData);

		function collectKeys(obj: any, path: string[], acc: any, lang: string) {
			for (const key in obj) {
				const currentPath = [...path, key];

				if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
					if (!acc[key]) acc[key] = {};
					collectKeys(obj[key], currentPath, acc[key], lang);
				} else {
					if (!acc._values) acc._values = [];
					let entry = acc._values.find((e: any) => e.key === key);
					if (!entry) {
						entry = { key: key, missing: [...languages] };
						acc._values.push(entry);
					}
					entry.missing = entry.missing.filter(
						(l: string) => l !== lang
					);
				}
			}
		}

		languages.forEach((lang) => {
			collectKeys(fakeData[lang], [], result, lang);
		});

		function cleanUp(obj: any) {
			if (obj._values) {
				obj._values.forEach((value: any) => {
					if (value.missing && value.missing.length === 0) {
						delete value.missing;
					}
				});
			}
			for (const key in obj) {
				if (key !== "_values") {
					cleanUp(obj[key]);
				}
			}
		}

		cleanUp(result);
		return sortData(result);
	}, []);

	const sortData = useCallback((data: any) => {
		const sortedData: any = {};
		const keys = Object.keys(data);

		keys.sort((a, b) => {
			if (a === "_values") return 1;
			if (b === "_values") return -1;
			return 0;
		});

		keys.forEach((key) => {
			if (key === "_values") {
				sortedData[key] = data[key];
			} else {
				sortedData[key] = sortData(data[key]);
			}
		});

		sortedData._child_missing = checkChildMissing(sortedData);

		return sortedData;
	}, []);

	const checkChildMissing = useCallback((data: any): boolean => {
		if (data._values) {
			for (const value of data._values) {
				if (value.missing && value.missing.length > 0) {
					return true;
				}
			}
		}
		for (const key in data) {
			if (key !== "_values" && typeof data[key] === "object") {
				if (checkChildMissing(data[key])) {
					return true;
				}
			}
		}
		return false;
	}, []);

	useEffect(() => {
		if (status === "idle") {
			request();
		}
	}, [request, status]);

	useEffect(() => {
		if (!data) return;
		methods.reset(data);
		const finalResult = generateResult(data);
		setParsed(finalResult);
		setLanguages(Object.keys(data));
	}, [data, generateResult, methods]);


	const removeEmptyStrings = (obj: FakeData): FakeData => {
		let newObj: FakeData = {};
	
		for (let key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				let nestedObj = removeEmptyStrings(obj[key] as FakeData);
				if (Object.keys(nestedObj).length > 0) {
					newObj[key] = nestedObj;
				}
			} else if (obj[key] !== "") {
				newObj[key] = obj[key];
			}
		}
		return newObj;
	};
		

	return (
		<div>
			<FormProvider {...methods}>
				<CustomForm
					onSubmit={methods.handleSubmit((data) => {
						// update(data)
						update(removeEmptyStrings(data))
					})}
				>
					{parsed && languages && (
						<LanguagesFields data={parsed} languages={languages} />
					)}
					<FixedSubmit type="submit" text="actions.save" />
				</CustomForm>
			</FormProvider>
		</div>
	);
});

const FixedSubmit = styled(Button)`
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: 200px;
`;

const CustomForm = styled.form`
	display: flex;
	flex-direction: column;
`;

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ChartType,
	BarElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ColorName, useTheme } from "../system/themes";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);

type Props = {
	datasets: {
		data: (number | undefined)[];
		type?: "line" | "bar";
		label: string;
	}[];
	labels: string[];
	colors: ColorName[];
};

export const LineChart: React.FC<Props> = ({ datasets, labels, colors }) => {
	const themes = useTheme();
	const chartOptions = {
		plugins: {
			legend: {
				labels: {
					color: themes.theme.black,
				},
			},
		},
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 1,
		scales: {
			y: {
				// not 'yAxes: [{' anymore (not an array anymore)
				ticks: {
					color: themes.theme.black, // n
				},
				grid: {
					color: themes.theme.grayLight,
				},
			},
			x: {
				// not 'yAxes: [{' anymore (not an array anymore)
				ticks: {
					color: themes.theme.black, // n
				},
				grid: {
					color: themes.theme.grayLight,
				},
			},
		},
	};
	const chartData = {
		datasets: datasets
			.filter((dataset) => dataset.data.length > 0)
			.map((dataset, i) => ({
				data: dataset.data,
				label: dataset.label,
				type: (dataset.type ?? "line") as ChartType,
				borderColor: themes.theme[colors[i]],
				backgroundColor: themes.theme[colors[i]],
			})),
		labels: labels,
	};
	// @ts-ignore: Unreachable code error
	return <Line data={chartData} options={chartOptions} />;
};

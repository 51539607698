import styled, { CSSObject } from 'styled-components'

export const Suggestion = styled.div<{
  backgroundColor: CSSObject['backgroundColor']
  color: CSSObject['color']
}>`
  padding: 2px 5px;
  border-radius: 5px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  opacity: 0;
  font-size: 11px;
  position: relative;
  top: -2px;
  left: 50%;
`

import Table from "../../../components/system/Table";
import Paginate from "../../../components/system/Table/Paginate";
import { Flex } from "../../../components/system/Table/Flex";
import { ReactElement, useState, useMemo, useEffect } from "react";
import { Column, Row } from "react-table";
import { useTranslation } from "react-i18next";
import CellLink from "../../../components/system/Table/CellLink";
import { useParams } from "react-router-dom";
import { Tag } from "../../../components/system/Tag";
import { CustodyLevel, Ownership } from "../../../types";
import Actions from "../../../components/system/Table/Actions";
import { roleColors } from "../../../utils/costants/tagColors";
import { ROUTES } from "../../../utils";
import { useGetUserOwnershipQuery } from "../operations/__generated__/getUserOwnership.generated";
import toast from "react-hot-toast";
import { UserOwnershipsFragment } from "../operations/__generated__/user-ownerships.generated";
import { Subtitle } from "../../../components/system/Typography";
import styled from "styled-components";
import { Text } from "../../../components/system/Typography";
import { useDeleteOwnershipMutation } from "../operations/__generated__/delete-ownership.generated";

export const UserPets = (): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const [owData, setOwData] = useState<UserOwnershipsFragment>();
    const [olData, setOlData] = useState<UserOwnershipsFragment>();
    const [deletingOnLoan, setDeletingOnLoan] = useState(false);
    const [deletingOwned, setDeletingOwned] = useState(false);
    const [owPage, setOwPage] = useState(1);
    const [owPageSize, setOwPageSize] = useState(5);
    const [olPage, setOlPage] = useState(1);
    const [olPageSize, setOlPageSize] = useState(5);

    const [deleteOwnership, { loading: deleteLoading }] =
        useDeleteOwnershipMutation({
            onCompleted: (data) => {
                refetchTable();
            },
        });

    const refetchTable = () => {
        if (deletingOnLoan) {
            onLoanPets.refetch();
            setDeletingOnLoan(false);
            return;
        }
        ownedPets.refetch();
        setDeletingOwned(false);
    };

    const ownedPets = useGetUserOwnershipQuery({
        variables: {
            id: id!,
            commonSearch: {
                page: owPage - 1,
                page_size: owPageSize,
                filters: {
                    fixed: [
                        { key: "custody_level", value: CustodyLevel.Owner },
                    ],
                },
            },
        },
        onCompleted: ({ getUser }) => {
            if (getUser.error) {
                toast.error(t("errors.fetch"));
                return;
            }
            if (getUser.success && getUser.user && getUser.user.ownerships) {
                setOwData(getUser.user.ownerships);
            }
        },
        onError: () => {
            toast.error(t("errors.fetch"));
            return;
        },
    });
    const onLoanPets = useGetUserOwnershipQuery({
        variables: {
            id: id!,
            commonSearch: {
                page: olPage - 1,
                page_size: olPageSize,
                filters: {
                    lists: [
                        {
                            key: "custody_level",
                            value: [
                                CustodyLevel.PetSitter,
                                CustodyLevel.SubOwner,
                            ],
                        },
                    ],
                },
            },
        },
        onCompleted: ({ getUser }) => {
            if (getUser.error) {
                toast.error(t("errors.fetch"));
                return;
            }
            if (getUser.success && getUser.user && getUser.user.ownerships) {
                setOlData(getUser.user.ownerships);
            }
        },
    });

    const { t } = useTranslation();

    useEffect(() => {
        console.log("deleting onLoan :", deleteLoading && deletingOnLoan);
        console.log("deleting owned  :", deleteLoading && deletingOwned);
    }, [deleteLoading, deletingOnLoan, deletingOwned]);

    const columns = useMemo<
        Column<Omit<Ownership, "user"> & { actions: any }>[]
    >(
        () => [
            {
                Header: () => (
                    <Text color="black" weight={600} text="pets.name" />
                ),
                accessor: (data) => data.pet.name,
                Cell: ({
                    row,
                    value,
                }: {
                    row: Row<Omit<Ownership, "user"> & { actions: any }>;
                    value: string;
                }) => (
                    <CellLink
                        to={`${ROUTES.PETS}/${row.original.pet.id}`}
                        ntText={value}
                    />
                ),
                id: "pet_name",
            },
            {
                Header: () => (
                    <Text
                        color="black"
                        weight={600}
                        text="pets.ownerships_type"
                    />
                ),
                accessor: "custody_level",
                Cell: ({ row, value }) => (
                    <CellLink to={`${ROUTES.PETS}/${row.original.pet.id}`}>
                        <Tag
                            color={roleColors[value as CustodyLevel]}
                            text={t(`pets.roles.${value}`).toLowerCase()}
                        />
                    </CellLink>
                ),
            },
            {
                Header: () => (
                    <Text color="black" weight={600} text="actions.actions" />
                ),
                id: "actions",
                Cell: ({
                    row,
                }: {
                    row: Row<Omit<Ownership, "user"> & { actions: any }>;
                }) => (
                    <Actions
                        onDelete={() => {
                            deleteOwnership({
                                variables: { id: row.original.id },
                            });
                            if (
                                row.original.custody_level ===
                                CustodyLevel.Owner
                            ) {
                                setDeletingOwned(true);
                                return;
                            }
                            setDeletingOnLoan(true);
                        }}
                    />
                ),
            },
        ],
        [t, deleteOwnership]
    );

    return (
        <Container>
            <Flex
                justifyContent="center"
                flexDirection="column"
                padding="30px 0 40px 0"
            >
                <Subtitle
                    color={"black"}
                    text="users.pets_owned"
                    style={{ marginBottom: "20px" }}
                />
                {owData && (
                    <>
                        <Table
                            data={owData}
                            columns={columns}
                            loading={
                                ownedPets.loading ||
                                (deleteLoading && deletingOwned)
                            }
                        />

                        <Paginate
                            totalPages={owData.pagination.total_pages || 0}
                            limit={owData.pagination.page_size || 0}
                            pageSize={owPageSize}
                            pageSizes={[2, 5, 10]}
                            currentItems={owData.items.length}
                            includeAll
                            setPageSize={setOwPageSize}
                            totalRows={owData.pagination.total_items || 0}
                            page={owPage}
                            setPage={setOwPage}
                        />
                    </>
                )}
            </Flex>
            <Flex
                justifyContent="center"
                flexDirection="column"
                padding="30px 0 40px 0"
            >
                <Subtitle
                    color="black"
                    text="users.pets_foster_care"
                    style={{ marginBottom: "20px" }}
                />
                {olData && (
                    <>
                        <Table
                            data={olData}
                            columns={columns}
                            loading={
                                onLoanPets.loading ||
                                (deleteLoading && deletingOnLoan)
                            }
                        />
                        <Paginate
                            totalPages={olData.pagination.total_pages || 0}
                            limit={olData.pagination.page_size || 0}
                            pageSize={olPageSize}
                            pageSizes={[2, 5, 10]}
                            currentItems={olData.items.length}
                            includeAll
                            setPageSize={setOlPageSize}
                            totalRows={olData.pagination.total_items || 0}
                            page={olPage}
                            setPage={setOlPage}
                        />
                    </>
                )}
            </Flex>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

import styled from 'styled-components'
import {ColorName, useTheme } from '../system/themes'

export const TabsContainer = styled.div<{bgColor?: ColorName}>`
  width: 100%;
  padding:  40px;
  border-radius: 12px;
  background-color: ${({bgColor})=> useTheme().theme[bgColor ?? "pureWhite"]};
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
  @media screen and (max-width: 600px){
    padding:  20px;
  }
`

import React, { useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Media from "react-media";

import LogoLight from "../../images/logo-light.svg";
import LogoDark from "../../images/logo-dark.svg";
import { storage } from "../../data/storage";
import { Header } from "./Header";
import { useTheme, themes as themesConf } from "./themes";
import { Sidebar } from "./Sidebar";
import { Button } from "./Button";
import { I18NKey } from "../../i18n";
import { User } from "../../types";

export const SessionContext = React.createContext<{
	user?: User;
	mustReload: () => void;
	flagDirty: () => void;
	resetDirty: () => void;
	toggleSidebarOpen: () => void;
}>({
	mustReload: () => ({}),
	flagDirty: () => ({}),
	resetDirty: () => ({}),
	toggleSidebarOpen: () => ({}),
});

export const MainLayout: React.FC<{
	title: I18NKey;
	backLink?: { to: string; text: I18NKey };
	headerButton?: { text: I18NKey; to: string };
	headerLeft?: React.ReactNode;
	children?: React.ReactNode;
}> = ({ children, title, backLink, headerButton, headerLeft }) => {
	const themes = useTheme();
	const location = useLocation();
	const history = useNavigate();
	const Logo = themes.theme === themesConf.dark ? LogoDark : LogoLight;
	// If a form is dirty (with unsaved user work on it) the page sets dirty to true.
	// If the user then tries to go back, a popup is shown
	const [dirty, setDirty] = useState(false);

	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const toggleSidebarOpen = useCallback(
		() => setSidebarOpen((open) => !open),
		[]
	);

	const [user, setUser] = useState(storage.getUser());
	const mustReload = useCallback(() => setUser(storage.getUser()), []);

	const flagDirty = useCallback(() => {
		window.onbeforeunload = () => "";
		setDirty(true);
	}, []);
	const resetDirty = useCallback(() => {
		window.onbeforeunload = () => null;
		setDirty(false);
	}, []);

	return (
		<Grid>
			<SessionContext.Provider
				value={{
					mustReload,
					flagDirty,
					resetDirty,
					toggleSidebarOpen,
					user,
				}}
			>
				<Media queries={{ small: "screen and (max-width: 600px)" }}>
					{(matches) => (
						<>
							{(!matches.small || isSidebarOpen) && (
								<SidebarContainer>
									<Sidebar
										structure={[
											{
												name: "dashboard",
												label: "dashboard.dashboard",
												showArrow: false,
												icon: "grid",
											},
											{
												name: "users",
												label: "users.users",
												showArrow: false,
												icon: "people",
											},
											{
												name: "pets",
												label: "pets.pets",
												showArrow: false,
												icon: "paw",
											},
											{
												name: "statistics",
												label: "statistics.statistics",
												showArrow: false,
												icon: "bar-chart",
											},
											{
												name: "translations",
												label: "translations.translations",
												showArrow: false,
												icon: "text",
											},
										]}
										pinned={[
											{
												name: "me",
												ntLabel: user
													? `${user.first_name} ${user.last_name}`
													: "Unknown",
												showArrow: false,
												icon: "person-circle",
											},
											{
												name: "logout",
												label: "auth.logout",
												showArrow: false,
												icon: "log-out-outline",
												reverseIcon: true,
											},
										]}
										logoStyles={{ width: "50%" }}
										selected={
											location.pathname.split("/")[1]
										}
										color="white"
										selectedTextColor="trueWhite"
										textColor="black"
										selectedColor="secondary"
										versionColor="black"
										logo={Logo}
										onSelect={(name) => {
											if (matches.small) {
												setSidebarOpen(false);
											}
											switch (name) {
												case "logout":
													storage.deleteToken();
													storage.deleteUser();
													history("/login");
													break;
												default:
													if (dirty) {
														window.location.href = `/${name}`;
													} else {
														history(`/${name}`);
													}
													break;
											}
										}}
									/>
								</SidebarContainer>
							)}
							{(!matches.small || !isSidebarOpen) && (
								<HeaderContainer
									color={themes.theme["whiteDark"]}
								>
									<Header
										title={title}
										backLink={backLink}
										dirty={dirty}
										leftSide={
											headerButton ? (
												<Button
													text={headerButton.text}
													onClick={() => {
														if (dirty) {
															return (window.location.href =
																headerButton.to);
														}
														history(
															headerButton.to
														);
													}}
													size="large"
												/>
											) : (
												headerLeft
											)
										}
									></Header>
								</HeaderContainer>
							)}
							{(!matches.small || !isSidebarOpen) && (
								<ContentContainer
									color={themes.theme["whiteLight"]}
								>
									{children}
								</ContentContainer>
							)}
						</>
					)}
				</Media>
			</SessionContext.Provider>
		</Grid>
	);
};

const Grid = styled.div`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	border: none;
	display: grid;
	grid-template-columns: 220px 1fr;
	grid-auto-rows: minmax(min-content, max-content) 1fr;
	@media screen and (max-width: 600px) {
		grid-template-columns: 1fr;
	}
`;

const SidebarContainer = styled.div`
	width: 220px;
	max-height: 100vh;
	height: 100%;
	grid-area: 1 / 1 / 3 / 2;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	@media screen and (max-width: 600px) {
		min-width: 100vw;
		min-height: 100vh;
		position: fixed;
		max-height: unset;
		grid-area: 1 / 1 / 3 / 2;
	}
`;

const HeaderContainer = styled.div<{ color: string }>`
	background-color: ${(props) => props.color};
	width: 100%;
	grid-area: 1 / 2 / 2 / 3;
	@media screen and (max-width: 600px) {
		grid-area: 1 / 1 / 2 / 2;
	}
`;

const ContentContainer = styled.div<{ color: string }>`
	background-color: ${(props) => props.color};
	width: 100%;
	grid-area: 2 / 2 / 3 / 3;
	padding: 20px;
	overflow-y: scroll;
	@media screen and (max-width: 600px) {
		grid-area: 2 / 1 / 3 / 2;
	}
`;

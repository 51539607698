import { User } from '../types'

class Storage {
  getToken = () => localStorage.getItem('jwt')
  saveToken = (token: string) => localStorage.setItem('jwt', token)
  deleteToken = () => localStorage.removeItem('jwt')

  getTheme = () => localStorage.getItem('theme') || 'light'
  saveTheme = (theme: string) => localStorage.setItem('theme', theme)
  deleteTheme = () => localStorage.removeItem('theme')

  getUser = () => JSON.parse(localStorage.getItem('user') || 'null') as User
  saveUser = (user?: Omit<User, "ownerships"| "created_at" | "pets_on_loan" | "pets_owned">) => localStorage.setItem('user', JSON.stringify(user))
  deleteUser = () => localStorage.removeItem('user')
}
export const storage = new Storage()

import { useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Icon } from "./Icon";
//import { ThemeContext, ColorName } from "./common/theme";
import {
  ThemesContext,
  ColorName,
  themes as themesConf,
} from "./themes";
import { I18NKey } from "../../i18n";
import { Toggle } from "./Toggle";
import { IonIcons } from "react-ion-icon";
import { Text } from "./Typography";

type RowStructure = {
  name: string;
  label?: I18NKey;
  icon?: IonIcons
  ntLabel?: string;
  showArrow?: boolean;
  children?: RowStructure[];
  reverseIcon?: boolean;
};
export type SidebarStructure = RowStructure[];

type Props = {
  structure?: SidebarStructure;
  pinned?: SidebarStructure;
  selected?: string;
  color?: ColorName;
  selectedTextColor?: ColorName;
  textColor?: ColorName;
  bottomColor?: ColorName;
  selectedColor?: ColorName;
  logo?: string;
  logoStyles?: Record<string, unknown>;
  onSelect?: (name: string) => void;
  stickBottom?: boolean;
  version?: string;
  versionColor?: ColorName;
  compact?: boolean;
  style?: Record<string, unknown>;
  className?: string;
};

export const Sidebar = ({
  structure = [],
  pinned = [],
  selected,
  color = "primary",
  selectedColor = "secondary",
  logo,
  logoStyles,
  onSelect = (s: string) => ({}),
  stickBottom = false,
  compact = false,
  version,
  versionColor = "gray",
  textColor = "black",
  selectedTextColor = "gray",
  bottomColor = "secondaryDark",
  style,
  className,
}: Props) => {
  const themes = useContext(ThemesContext);

  const [openSection, setOpenSection] = useState<string | null>(null);

  return (
    <StyledSidebar
      color={themes.theme[color]}
      style={style}
      className={className}
    >
      <div style={{ width: "100%", overflow: "visible" }}>
        {logo && <Logo src={logo} alt="Logo" style={logoStyles} />}
        {structure.map((row) => (
          <Row
            key={row.name}
            {...row}
            compact={compact}
            size="standard"
            selectedBackgroundColor={themes.theme[selectedColor]}
            color={themes.theme["white"]}
            open={openSection === row.name}
            onSelect={onSelect}
            onOpen={() =>
              setOpenSection((os) => (os === row.name ? null : row.name))
            }
            textColor={
              selected === row.name
                ? themes.theme[selectedTextColor]
                : themes.theme[textColor]
            }
            isSelected= {selected === row.name}
            backgroundColor={
              selected === row.name ||
              row.children?.map((c) => c.name).includes(selected || "")
                ? themes.theme[selectedColor]
                : themes.theme[color]
            }
            selected={selected}
          />
        ))}
      </div>
      {stickBottom && <div style={{ flexGrow: 1 }} />}
      <BottomContainer color={themes.theme[bottomColor]} stick={stickBottom}>
        {pinned.map((row) => (
          <Row
            key={row.name}
            {...row}
            size="standard"
            color={themes.theme["white"]}
            onSelect={onSelect}
            textColor={themes.theme["white"]}
            selected={selected}
            compact={compact}
            isSelected= {selected === row.name}
            selectedBackgroundColor= {themes.theme[selectedColor]}
            backgroundColor={ themes.theme[bottomColor] }
          />
        ))}
        {version && (
          <Row
            name="version"
            size="small"
            ntLabel={version}
            compact={compact}
            showArrow={false}
            selectedBackgroundColor={themes.theme[selectedColor]}
            color={themes.theme[versionColor]}
            textColor={themes.theme["white"]}
            backgroundColor={themes.theme[color]}
            onSelect={() => ({})}
            selected={selected}
          />
        )}
          <DarkModeContainer>
            <Text text="dark_mode" /> 
            <Toggle
              value={themes.theme === themesConf.light}
              onChange={themes.toggleTheme}
              barColor={themes.theme['gray']}
              btnColor= {themes.theme['whiteDark']}
              leftElement= { <Icon name="moon"  color={themes.theme['black']} size="16px" /> }
              rigthElement= { <Icon name="sunny" color={themes.theme['black']} size="16px" /> }
            />
          </DarkModeContainer>
      </BottomContainer>
    </StyledSidebar>
  );
};

const Row = ({
  name,
  label,
  ntLabel,
  icon,
  reverseIcon = false,
  open = false,
  children = [],
  showArrow,
  color,
  backgroundColor,
  selectedBackgroundColor,
  selected,
  onSelect,
  onOpen = () => ({}),
  isSelected,
  textColor,
  size,
  child = false,
  greyedOut = false,
  padBottom = false,
  compact = false,
}: RowStructure & {
  color: string;
  size: "small" | "standard";
  backgroundColor: string;
  selected?: string;
  open?: boolean;
  child?: boolean;
  greyedOut?: boolean;
  selectedBackgroundColor: string;
  textColor: string;
  padBottom?: boolean;
  isSelected?: boolean
  compact?: boolean;
  onSelect: (name: string) => void;
  onOpen?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={isSelected ? 'selected' : ''} style={{ display: "flex", flexDirection: "column",  position:"relative" }}>
      <RowStyles
        key={name}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          if (children.length > 0) {
            onOpen();
          } else {
            onSelect(name);
          }
        }}
        color={greyedOut ? `${color}BF` : color}
        backgroundColor={backgroundColor}
        selectedBackgroundColor={selectedBackgroundColor}
        textColor={textColor}
        size={size}
        child={child}
        padBottom={padBottom}
      >
        <LeftIcon>
          {icon ? (
            <Icon name={icon} size="18px" color={textColor} reverse={reverseIcon} />
          ) : (
            <div style={{ width: '18px', height: '18px', color: 'transparent' }} />
          )}
        </LeftIcon>
        <span>{label ? t(label) : ntLabel}</span>
      </RowStyles>

      <ChildAnimatedPane open={open} childNumber={children.length}>
        {children.map((child, i) => (
          <Row
            key={child.name}
            {...child}
            {...{ color, size, backgroundColor, onSelect, selected, textColor }}
            child
            selectedBackgroundColor={selectedBackgroundColor}
            greyedOut={selected !== child.name}
            padBottom={i === children.length - 1}
          />
        ))}
      </ChildAnimatedPane>
    </div>
  );
};

type StyledProps = {
  color: string;
};
const StyledSidebar = styled.div<StyledProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
  margin: 0;
  border: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  
`;

type StyledLabelProps = {
  backgroundColor: string;
  selectedBackgroundColor: string;
  color: string;
  size: "standard" | "small";
  child: boolean;
  padBottom: boolean;
  textColor: string;
};
const textStyles = {
  standard: { size: 13, weight: 600, mt: 3 },
  small: { size: 10, weight: 400, mt: 0 },
};
const RowStyles = styled.button<StyledLabelProps>`
  font-family: "Open Sans", sans-serif;
  font-size: ${(props) => textStyles[props.size].size}px;
  font-weight: ${(props) => textStyles[props.size].weight};
  padding-left: ${(props) => (props.child ? 30 : 15)}px;
  padding-top: 13px;
  padding-bottom: ${(props) => (props.padBottom ? 23 : 13)}px;
  outline: none;
  color: ${(props) => props.textColor};
  border: none;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color 0.3s ease;
  z-index: ${(props) => (props.child ? 0 : 1)};
  cursor: pointer;
  > * {
    z-index: 2;
  }
  .selected &{
    :before {
      content: "";
      background-color:  ${(props) => props.selectedBackgroundColor};
      border-radius: 0 50px 50px 0 ; 
      left: 0;
      position:absolute;
      width:calc(100% + 20px);
      height: 100%;
      z-index: 1;
    }
  }
`;

const Logo = styled.img`
  width: 50%;
  margin-top: 30px;
  margin-left: 25%;
  margin-bottom: 20px;
`;

const LeftIcon = styled.div`
  margin-right: 15px;
  margin-left: 20px;
  margin-top: 3px;
`;

// const RightArrow = styled.div<{ mustRotate?: boolean }>`
//   margin-left: auto;
//   margin-right: 20px;
//   margin-top: 3px;
//   transform: ${(props) => (props.mustRotate ? "rotate(90deg)" : "none")};
//   transition: transform 0.1s ease-out;
// `;

const ChildAnimatedPane = styled.div<{ open: boolean; childNumber: number }>`
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  height: ${(props) => (props.open ? "inherit" : "0")};
  max-height: ${(props) =>
    props.open ? `${props.childNumber * 50 + 40}px` : "0"};
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  flex: ${(props) => (props.open ? 1 : 0)};
  overflow: hidden;
`;

const BottomContainer = styled.div<{ color: string; stick: boolean }>`
  width: 100%;
  padding-bottom: 20px;
  ${(props) =>
    props.stick
      ? `
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 10;
  `
      : `
  margin-top: auto;
  `}
  background-color: ${(props) => props.color};
  > * {
    :nth-last-child(2) {
      margin-bottom: 20px;
    }
  }
`;

const DarkModeContainer = styled.div`
  width:100%;
  display: flex;
  justify-content: space-around;
`
import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type UserTreatmentFragment = (
  { __typename?: 'Treatment' }
  & Pick<Types.Treatment, 'id' | 'date' | 'name' | 'type' | 'created_at'>
  & { booster?: Types.Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Types.Treatment, 'name' | 'date' | 'id'>
  )>, health_card?: Types.Maybe<(
    { __typename?: 'HealthCard' }
    & { pet: (
      { __typename?: 'Pet' }
      & Pick<Types.Pet, 'id' | 'name'>
    ) }
  )> }
);

export const UserTreatmentFragmentDoc = gql`
    fragment UserTreatment on Treatment {
  id
  date
  name
  type
  created_at
  booster {
    name
    date
    id
  }
  health_card {
    pet {
      id
      name
    }
  }
}
    `;
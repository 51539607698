import React, { useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Media from 'react-media'

import { SessionContext } from './MainLayout'
import { useTheme } from './themes'
//import { useTheme } from './common/theme'
import { HeaderTitle } from './Typography'
import { I18NKey } from '../../i18n'
import { useTranslation } from 'react-i18next'
import { Icon } from './Icon'

type Props = {
  title: I18NKey
  backLink?: { to: string; text: I18NKey }
  leftSide?: React.ReactNode
  dirty?: boolean
}

export const Header: React.FC<Props> = ({ title, backLink, leftSide, dirty }) => {
  const history = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const { toggleSidebarOpen } = useContext(SessionContext)
  return (
    <Container>
      <TitleContainer>
        <Media queries={{ small: 'screen and (max-width: 600px)' }}>
          {(matches) =>
            matches.small && (
              <HamburgerIcon onClick={toggleSidebarOpen} />
            )
          }
        </Media>
        <StyledTitle color="blackLight" text={title} />
        <div style={{ flexGrow: 1 }} />
        <div>{leftSide}</div>
      </TitleContainer>
      {backLink && (
        <BackContainer
          onClick={() => {
            if (dirty) {
              return (window.location.href = backLink.to)
            }
            history(backLink.to)
          }}
        >
          <Icon name="caret-back" color={theme.theme['grayDark']} />
          <BackText color={theme.theme['gray']}>{t(backLink.text)}</BackText>
        </BackContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 20px 60px;
  margin: 0;
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-between;
  align-items: center;
`

const StyledTitle = styled(HeaderTitle)`
  display: inline-block;
  margin: 0;
`

const BackContainer = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const BackText = styled.p<{ color: string }>`
  font-size: 13px;
  line-height: 24px;
  margin-left: 12px;
  color: ${(props) => props.color};
`

const HamburgerIcon = styled.div`
  margin-right: 10px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  background-color: #7b7b7b;
  cursor: pointer;
`

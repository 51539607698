import React from "react";
import styled from "styled-components";
import { Label, Subtitle } from "../system/Typography";
import dayjs from 'dayjs'
import { SpecialIcon } from "./SpecialIcon";
import { IconName } from "./SpecialIcons";
import { ColorName, useTheme } from "../system/themes";
import { Link } from "../../modules/auth/components/Link";
import { UserTreatmentFragment } from "../../modules/users/operations/__generated__/user-treatment.generated";
import { PetTreatmentFragment } from "../../modules/pets/operations/__generated__/pet-treatment.generated";

type props = {
  treatment: PetTreatmentFragment | UserTreatmentFragment ;
  backColor?: ColorName;
  foreColor?: ColorName;
  showRef? : boolean

};

export const TreatmentItem: React.FC<props> = ({ treatment, foreColor="white" , backColor="primary", showRef = false }) => {
  const themes = useTheme()

  const isPetFragment = (treatment: PetTreatmentFragment | UserTreatmentFragment): treatment is PetTreatmentFragment => {
    if('health_card' in treatment){
      return true
    }
    return false
  }
  return (
    <TreatmentContainer>
      
      <TitleContainer color={ themes.theme[backColor] }>
        <Subtitle ntText={treatment.name} color="white" />
      </TitleContainer>
      <MainContainer color= { themes.theme[backColor] }>
        <IconContainer color={ themes.theme[foreColor] }>
          { treatment.type &&  <SpecialIcon name={treatment.type.toLowerCase() as IconName} color={backColor} />}
        </IconContainer>
        <DateContainer foreColor={ themes.theme[foreColor] } backColor={ themes.theme[backColor] }>
          <div className="left">
            <Label color="black" text='treatments.date' />
            <Label color="grayDark" ntText={dayjs(treatment.date).format('DD MMM YYYY')} />
          </div>
          { treatment.booster &&
            <div className="rigth">
            <Label color="black" text='treatments.booster_date' />
            <Label color="grayDark" ntText={dayjs(treatment.booster.date).format('DD MMM YYYY')} />
            </div>
          }
        </DateContainer>
        { showRef && isPetFragment(treatment)  && <PetRefContainer color={themes.theme[foreColor]}>
          <Link color={themes.theme.black } ntText={(treatment as UserTreatmentFragment).health_card!.pet.name} to={`/pets/${(treatment as UserTreatmentFragment).health_card!.pet.id}`} />
        </PetRefContainer>}
      </MainContainer>
      
    </TreatmentContainer>
  );
};

type foreProps = {
  color: string
}
type backProps = {
  color: string
}

type bothProps = {
  foreColor: string;
  backColor: string;
}

const TreatmentContainer = styled.div`
  width: 45%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 30px;
  height: 60px;
  @media screen and (max-width: 1280px ) {
    width:100%;
  }
`;

const TitleContainer = styled.div<backProps>`
    position: absolute;
    z-index: 1;
    width: 90%;
    padding: 5px;
    box-sizing: border-box;
    left: 30px;
    border-radius: 0 160px 0px 0px / 0 130px 0 0px;
    background-color: ${({color})=> color};
    top: 1px;
    padding-left: 43px;
    box-sizing: border-box;
`;

const MainContainer = styled.div<backProps>`
  position: absolute;
  width: 100%;
  z-index: 2;
  :before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    box-shadow: -2px 0px 0px 4px  ${({color})=> color};
  }
  :after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 80px;
    height: 20px;
    right: 0px;
    top: 35px;
    padding: 4px 10px;
    border-radius: 60px;
    box-shadow: 2px 2px 0px 4px  ${({color})=> color};
  }
`;

const IconContainer = styled.div<foreProps>`
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 2;
  padding:10px;
  background-color:  ${({color})=> color};
  border-radius: 60px;
  box-sizing: border-box;
  > * {
    width:100%;
    height: 100%;
  }
`;

const DateContainer = styled.div<bothProps>`
  height: 30px;
  position: absolute;
  width: calc(100% - 30px);
  background-color: ${({ foreColor })=> foreColor};
  bottom: 0;
  z-index: 1;
  left: 30px;
  top: 30px;
  padding-left: 30px;
  box-sizing: border-box;
  border-radius: 0 30px 30px 0;
  box-shadow: 2px 0px 1px 4px  ${({ backColor })=> backColor};
  display: flex;
  flex-flow: wrap;
  > * {
    align-items: center;
    display: flex;
    padding: 2px;
    box-sizing: border-box;
    > :first-child {
      margin-right: 4px;
    }
  }
  > :first-child {
    width: 35%;
  }
  > :last-child {
    width: 50%;
  }
`;


const PetRefContainer = styled.div<foreProps>`
  position: absolute;
  right: 0px;
  top: 35px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 999px;
  background-color:  ${({color})=> color};
  z-index: 3;
  padding: 4px 10px;
`
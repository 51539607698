import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ColorName, useTheme } from '../system/themes'
import { TabsContainer } from './TabsContainer'

export const CustomTabs: React.FC<{
  entries: { value: string; label: string; node: React.ReactElement }[]
  defaultValue: string
  style?: any
  bgColor?:ColorName
  textColor?: ColorName
  className?: string
}> = ({ entries, defaultValue, style, className, bgColor= "pureWhite", textColor="black" }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<string | null>(defaultValue)
  const themes = useTheme()
  return (
    <MainContainer>

      <TabNamesContainer>
        {entries.map(({ label, value }) => (
          <TabBox key={value} active={value === activeTab} bgColor={themes.theme[bgColor]} >
            <TabName color={themes.theme[textColor]} key={value} onClick={() => setActiveTab(value)}>
              {t(label)}
            </TabName>
          </TabBox>
        ))}
      </TabNamesContainer>
        <TabsContainer>
            {entries.find((entry) => entry.value === activeTab)?.node}
        </TabsContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
`

const TabNamesContainer = styled.div`
  /* border-bottom: 1px solid #E5E7EB; */
  /* box-shadow: inset 0px 0px 0px 1px #E5E7EB; */
  margin-right: 3%;
  width: 94%;
  margin-bottom: -10px;
  display: flex;
  flex-flow: row;
  align-items: center;
`

const TabBox = styled.div<{ active: boolean, bgColor: string }>`
  width: 144px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${(props) => (props.active ? props.bgColor : '#0000')};
`

const TabName = styled.button<{color: string}>`
  font-size: 13px;
  color: ${({color})=> color};
  line-height: 24px;
  @media screen and (max-width: 600px) {
    margin-right: 20px;
    margin-top: 20px;
    padding: 0 0px 10px 0px;
    font-size: 12px;
    line-height: 18px;
  }
  background: transparent;
  border: none;
  :focus {
    outline: none;
  }
  cursor: pointer;
  transition: all ease-in-out 0.2s;
`

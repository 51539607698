export const ROUTES = {
    LOGIN: '/login',
    HOME: '/',
    PASSWORD_RESET: '/password-reset',
    PASSWORD_OTP: '/password-otp',
    UPDATE_PASSWORD: '/update-password',
    USERS: '/users',
    PETS: '/pets',
    PROFILE_PAGE: '/me',
    USERS_DETAILS: '/users/:id',
    PETS_DETAILS: '/pets/:id',
    REPORTS: '/reports',
    DEVICES: '/devices',
    REPORTS_DETAIL: '/reports/:id',
    DEVICES_DETAIL: '/devices/:id',
    SETTINGS: '/settings',
    DASHBOARD: '/dashboard',
    STATISTICS: '/statistics',
    TRANSLATIONS: '/translations'

  }
  
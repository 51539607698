import { useTranslation } from "react-i18next";
import { IonIcons } from "react-ion-icon";
import styled from "styled-components";

import { ColorName, useTheme } from "./themes";
import { Icon } from "./Icon";
import { I18NKey } from "../../i18n";
import { Label } from "./Typography";
import { useNavigate } from "react-router-dom";

type Props = {
    text?: I18NKey;
    ntText?: string;
    to: string;
    labelColor?: ColorName;
    label?: I18NKey;
    ntLabel?: string
    color?: ColorName;
    iconRight?: IonIcons;
    style?: Record<string, unknown>;
};

export const InternalLink = ({
    text,
    label,
    ntLabel,
    labelColor ="black",
    color = "primary",
    to,
    ntText,
    iconRight = "link",
    style,
}: Props) => {
    const themes = useTheme();
    const { t } = useTranslation();
    const history  = useNavigate()
    const goTo = () => {
        history(to)
    };
    return (
        <Container>
            {(label || ntLabel )&& <Label text={label} ntText={ntLabel} color={labelColor} sizePx={13} lineHeightPx={20}/>}
            <Link onClick={goTo}>
                <Label ntText={text ? t(text) : ntText} color={color} />
                <Icon name={iconRight} color={themes.theme[color]} />
            </Link>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    > label {
        margin-bottom: 12px;
    }
`

const Link = styled.div`
    display: flex;
    width: 100%;
    cursor: pointer;
    > label {
        cursor: pointer;
        margin-right: 10px;
    }
    &:hover{
        > label {
            font-weight: 800;
        }
    }
`;

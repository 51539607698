import { Toaster } from 'react-hot-toast'
import { AppContextProvider } from './components/system/AppContext'

import AppRouter from './router'
import dayjs from 'dayjs'
//import { defaultTheme, ThemeContext } from './components/common/theme'
import { themes, ThemesContext } from './components/system/themes'
import { useState } from 'react'
const lng = localStorage.getItem('lang') || 'en'

function App() {
  dayjs().locale(lng)
  const [theme, setTheme] = useState(themes.light)

  const toggleTheme =()=> {
    setTheme( theme === themes.light ? themes.dark: themes.light)
  }
  return (
    <ThemesContext.Provider value={{theme,  toggleTheme} } >
      <AppContextProvider>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              fontFamily: "'Open Sans', sans-serif",
            },
          }}
          />
        <AppRouter />
      </AppContextProvider>
      </ThemesContext.Provider>
  )
}

export default App

import { Flex } from '../Flex'
import { useCallback } from 'react'
import { useTheme } from '../../themes'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import PageNumber from './PageNumber'
import PageSize from './PageSize'

interface Props {
  totalRows: number
  limit: number
  totalPages: number
  currentItems: number
  page: number
  pageSize: number
  pageSizes?: number[]
  includeAll?: boolean
  setPage: (page: number) => void
  setPageSize: (size: number) => void
}

const Paginate = ({
  totalRows,
  limit,
  totalPages,
  page,
  pageSize,
  currentItems,
  includeAll = false,
  pageSizes = [5, 10, 20],
  setPage,
  setPageSize,
}: Props) => {
  const numberPages: number = totalRows && limit ? Math.ceil(Number(totalRows / limit)) : 0
  const themes = useTheme()
  const { t } = useTranslation()

  const resetPage = useCallback(
    (size: number) => {
      setPage(1)
      setPageSize(size)
    },
    [setPage, setPageSize]
  )

  const renderInitialPages = useCallback(
    () => {
      const pages: any[] = []
      const next: any[] = []
      for (let i = page - 4; i < page; i++) {
        if (i >= 1) {
          if (i === page - 4 && i > 1) {
            pages.push(1)
          } else if (i === page - 3 && i > 2) {
            pages.push('...')
          } else {
            pages.push(i)
          }
        }
      }
      pages.push(page)
      for (let i = page + 4; i > page; i--) {
        if (i <= totalPages) {
          if (i === page + 4 && i < totalPages) {
            next.push(totalPages)
          } else if (i === page + 3 && i < totalPages - 1) {
            next.push('...')
          } else {
            next.push(i)
          }
        }
      }

      return pages.concat(_.reverse(next)).map((index) => (
        <PageNumber
          key={`page-number-${index}`}
          onClick={() => {
            if (!isNaN(index as number)) {
              setPage(index as number)
            }
          }}
          index={index}
          color={index === page ? themes.theme['white'] : themes.theme['black']}
          backgroundColor={index === page ? themes.theme['secondary'] : themes.theme['white']}
        />
      ))
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [numberPages, page]
  )

  return (
    <Flex width="100%">
      { 
      totalRows  > 0  ? (
        <>
          <PageSizes>
          {pageSizes.map((size, index) => (
            <PageSize
              color={pageSize === size ? themes.theme['white'] : themes.theme['black']}
              backgroundColor={pageSize === size ? themes.theme['secondary'] : themes.theme['white']}
              onClick={() => resetPage(size)}
              size={size}
              key={`page-size-${index}`}
            />
          ))}
          {includeAll && (
            <PageSize
              color={pageSize === 1000000 ? themes.theme['white'] : themes.theme['black']}
              backgroundColor={pageSize === 1000000 ? themes.theme['secondary'] : themes.theme['white']}
              onClick={() => resetPage(1000000)}
              size={t('list.all').toString()}
            />
          )}
        </PageSizes>
        <Count color={themes.theme['black']}> {t('table.total_items', {current: currentItems, total: totalRows})} </Count>
        <PageControls>
          <Flex alignItems="center" justifyContent="center">
            <>{renderInitialPages()}</>
          </Flex>
        </PageControls>
        </>)
        : (<></>)
      }
    </Flex>
  )
}

export default Paginate

const PageSizes = styled(Flex)`
  width: 40%;
  justify-content: left;
`

const PageControls = styled(Flex)`
  width: 40%;
  justify-content: right;
`

const Count = styled.span<{color: string}>`
  width:20%;
  display:flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({color})=> color}
`
import { IonIcons } from "react-ion-icon";
import styled, { CSSObject } from "styled-components";
import { Button, ButtonColorNames } from "../../Button";
import { Suggestion } from "../Suggestion";

interface Props {
  iconName: IonIcons;
  buttonColor: ButtonColorNames;
  suggestion: string;
  suggestionColor?: CSSObject["color"];
  suggestionBgColor?: CSSObject["backgroundColor"];
  onClick: () => void;
}

const TableButton = ({
  suggestion,
  suggestionColor = "#fff",
  suggestionBgColor = "#2b2b2b",
  onClick,
  iconName,
  buttonColor,
}: Props) => {
  return (
    <Container>
      <Button
        size="icon"
        leftIcon={iconName}
        color={buttonColor}
        onClick={onClick}
        radius={25}
      />
      {suggestion && (
        <Suggestion
          className="suggestion"
          color={suggestionColor}
          backgroundColor={suggestionBgColor}
        >
          {suggestion}
        </Suggestion>
      )}
    </Container>
  );
};

export default TableButton;

const Container = styled.div`
  width: 25px;
  height: 25px;
  display: flex;

  flex-wrap: wrap;
  :hover > .suggestion {
    opacity: 0.6;
    transition: opacity 0.75s cubic-bezier(0.86, -0.37, 1, 0.49);
  }

  > button {
    ion-icon {
      font-size: 15px;
    }
  }
`;

import styled, { CSSObject } from "styled-components";
import { ColorName, useTheme } from "../system/themes";

type Props = {
  miniLoaderBGColor?: ColorName;
  loaderBGColor?: ColorName;
  miniLoaderFGColor?: ColorName;
  loaderFGColore?: ColorName;
  size?: "large" | "medium" | "small";
  time?: string;
};

const loaderSizes : Record< string, { loaderWidth: string, miniloaderWidth : string, borderWidth: string, miniboarderWidth: string }>= {
    small: { loaderWidth: "50px", miniloaderWidth : "20px", borderWidth: "10px", miniboarderWidth: "10px"},
    medium: { loaderWidth: "70px", miniloaderWidth : "40px", borderWidth: "12px", miniboarderWidth: "12px"},
    large: { loaderWidth: "120px", miniloaderWidth : "80px", borderWidth: "18px", miniboarderWidth: "18px"},
}

export const Loader: React.FC<Props> = ({
  miniLoaderBGColor = "secondary",
  miniLoaderFGColor = "trasparent",
  loaderBGColor = "trasparent",
  loaderFGColore = "primary",
  size = "medium",
  time = "3s",
}) => {
  const themes = useTheme();


const loaderSize = loaderSizes[size];


  return (
    <MainLoader
      foregroundColor={themes.theme[loaderFGColore]}
      backgroundColor={themes.theme[loaderBGColor]}
      className="loader"
      size={loaderSize.loaderWidth}
      borderWidth= {loaderSize.borderWidth}
      time={time}
    >
      <MiniLoader
        foregroundColor={themes.theme[miniLoaderFGColor]}
        backgroundColor={themes.theme[miniLoaderBGColor]}
        className="miniloader"
        size={loaderSize.miniloaderWidth}
        borderWidth={loaderSize.miniboarderWidth}
        time={time}
      ></MiniLoader>
    </MainLoader>
  );
};

type LoaderProps = {
  backgroundColor: CSSObject["color"];
  foregroundColor: CSSObject["color"];
  borderWidth: string;
  size: string;
  time: string;
};

const MainLoader = styled.div<LoaderProps>`
  border: ${({borderWidth})=> borderWidth} solid ${({ foregroundColor }) => foregroundColor};
  border-radius: 999px;
  background-color: #00000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${({borderWidth})=> borderWidth} solid ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${({borderWidth})=> borderWidth} solid ${({ backgroundColor }) => backgroundColor};
  width:  ${({size})=> size };
  height: ${({size})=> size };
  -webkit-animation: spin ${({time})=> time } linear infinite; /* Safari */
  animation: spin ${({time})=> time } linear infinite;
`;

const MiniLoader = styled.div<LoaderProps>`
   border: ${({borderWidth})=> borderWidth} solid ${({ foregroundColor }) => foregroundColor};
  border-radius: 999px;
  background-color: #00000000;
  border-top: ${({borderWidth})=> borderWidth} solid ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${({borderWidth})=> borderWidth} solid ${({ backgroundColor }) => backgroundColor};
  width:  ${({size})=> size };
  height: ${({size})=> size };
  
  -webkit-animation: minispin ${({time})=> time } linear infinite; /* Safari */
  animation: minispin ${({time})=> time } linear infinite;
`;

import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IonIcons } from "react-ion-icon";
import styled from "styled-components";
import { I18NKey } from "../../i18n";
import { Icon } from "./Icon";
import { ColorName, useTheme } from "./themes";
import { Label } from "./Typography";

type Option = {
    value: any;
    label: string;
};

type props = {
    radioColor?: ColorName;
    selectedColor?: ColorName;
    control: any;
    radioIcon?: IonIcons,
    options: Option[];
    name: string;
    label?: I18NKey;
};

export const Radio: React.FC<props> = ({
    radioColor="gray",
    selectedColor="primary",
    radioIcon,
    control,
    options,
    name,
    label,
}) => {
    const { t } = useTranslation();
    const themes = useTheme()
    return (
        <Container>
            <Label ntText={label ? t(label) : name} color="black" sizePx={13} />
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => (
                    <RadioOptions>
                        {options.map((option) => (
                            <OptionDiv key={option.value} onClick={()=> onChange(option.value)}>
                                <Icon name={ option.value !== value ?  (radioIcon ?radioIcon : "radio-button-off") :   (radioIcon ?radioIcon : "radio-button-on") } color={themes.theme[ option.value === value ? selectedColor : radioColor]} />
                                <span style={{color:themes.theme['black']}} className={option.value === value ? 'selected' : ''}>{t(option.label)}</span>
                            </OptionDiv>
                        ))}
                    </RadioOptions>
                )}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    > * {
        &:first-child {
            margin-bottom: 10px;
        }
    }
`;

const RadioOptions = styled.div`
    display: flex;
    flex-flow: wrap;
    width: 100%;
    justify-content: flex-start;
`;

const OptionDiv = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    min-width: 30%;
    margin-right: 10px;
    > * {
        &:first-child {
            margin-right: 10px;
        }
    }
    .selected{
        font-weight: 600;
    }
`;

export enum BREEDS {
    AFFENPINSCHER = "AFFENPINSCHER",
    AFGHAN_HOUND = "AFGHAN_HOUND",
    AIDI = "AIDI",
    AIREDALE_TERRIER = "AIREDALE_TERRIER",
    AKITA = "AKITA",
    ALABAI = "ALABAI",
    ALASKAN_HUSKY = "ALASKAN_HUSKY",
    ALASKAN_MALAMUTE = "ALASKAN_MALAMUTE",
    ALPINE_DACHSBRACKE = "ALPINE_DACHSBRACKE",
    AMERICAN_COCKER_SPANIEL = "AMERICAN_COCKER_SPANIEL",
    AMERICAN_FOXHOUND = "AMERICAN_FOXHOUND",
    AMERICAN_PIT_BULL_TERRIER = "AMERICAN_PIT_BULL_TERRIER",
    AMERICAN_STAFFORDSHIRE_TERRIER = "AMERICAN_STAFFORDSHIRE_TERRIER",
    AMERICAN_WATER_SPANIEL = "AMERICAN_WATER_SPANIEL",
    ANATOLIAN_SHEPHERD_DOG = "ANATOLIAN_SHEPHERD_DOG",
    ANGLO_FRANCAIS_DE_PETITE_VENERIE = "ANGLO_FRANCAIS_DE_PETITE_VENERIE",
    APPENZELLER_SENNENHUND = "APPENZELLER_SENNENHUND",
    ARIEGE_POINTING_DOG = "ARIEGE_POINTING_DOG",
    ARIEGEOIS = "ARIEGEOIS",
    AUSTRALIAN_CATTLE_DOG = "AUSTRALIAN_CATTLE_DOG",
    AUSTRALIAN_KELPIE = "AUSTRALIAN_KELPIE",
    AUSTRALIAN_SHEPHERD = "AUSTRALIAN_SHEPHERD",
    AUSTRALIAN_SILKY_TERRIER = "AUSTRALIAN_SILKY_TERRIER",
    AUSTRALIAN_TERRIER = "AUSTRALIAN_TERRIER",
    AUSTRIAN_BLACK_AND_TAN_HOUND = "AUSTRIAN_BLACK_AND_TAN_HOUND",
    AUSTRIAN_SHORTHAIRED_PINSCHER = "AUSTRIAN_SHORTHAIRED_PINSCHER",
    AZAWAKH = "AZAWAKH",
    BARBET = "BARBET",
    BASENJI = "BASENJI",
    BASSET_ARTESIEN_NORMAND = "BASSET_ARTESIEN_NORMAND",
    BASSET_BLEU_DE_GASCOGNE = "BASSET_BLEU_DE_GASCOGNE",
    BASSET_FAUVE_DE_BRETAGNE = "BASSET_FAUVE_DE_BRETAGNE",
    BASSET_HOUND = "BASSET_HOUND",
    BAVARIAN_MOUNTAIN_SCENTHOUND = "BAVARIAN_MOUNTAIN_SCENTHOUND",
    BEAGLE = "BEAGLE",
    BEAGLE_HARRIER = "BEAGLE_HARRIER",
    BEARDED_COLLIE = "BEARDED_COLLIE",
    BEAUCERON = "BEAUCERON",
    BEDLINGTON_TERRIER = "BEDLINGTON_TERRIER",
    BELGIAN_GRIFFONS = "BELGIAN_GRIFFONS",
    BELGIAN_SHEPHERD_GROENENDAEL = "BELGIAN_SHEPHERD_GROENENDAEL",
    BELGIAN_SHEPHERD_LAEKENOIS = "BELGIAN_SHEPHERD_LAEKENOIS",
    BELGIAN_SHEPHERD_MALINOIS = "BELGIAN_SHEPHERD_MALINOIS",
    BELGIAN_SHEPHERD_TERVUREN = "BELGIAN_SHEPHERD_TERVUREN",
    BERGAMASCO_SHEPHERD_DOG = "BERGAMASCO_SHEPHERD_DOG",
    BERGER_DES_PICARD = "BERGER_DES_PICARD",
    BERGER_DES_PYRENEES = "BERGER_DES_PYRENEES",
    BERNESE_MOUNTAIN_DOG = "BERNESE_MOUNTAIN_DOG",
    BICHON_FRISE = "BICHON_FRISE",
    BICHON_HAVANAIS = "BICHON_HAVANAIS",
    BILLY = "BILLY",
    BLACK_AND_TAN_COONHOUND = "BLACK_AND_TAN_COONHOUND",
    BLACK_NORWEGIAN_ELKHOUND = "BLACK_NORWEGIAN_ELKHOUND",
    BLUE_PICARDY_SPANIEL = "BLUE_PICARDY_SPANIEL",
    BOERBOEL = "BOERBOEL",
    BOLOGNESE = "BOLOGNESE",
    BORDER_COLLIE = "BORDER_COLLIE",
    BORDER_TERRIER = "BORDER_TERRIER",
    BORZOI = "BORZOI",
    BOSANSKI_OSTRODLAKI_GONIC_BARAK = "BOSANSKI_OSTRODLAKI_GONIC_BARAK",
    BOSTON_TERRIER = "BOSTON_TERRIER",
    BOUVIER_DE_ARDENNES = "BOUVIER_DE_ARDENNES",
    BOUVIER_DES_FLANDERS = "BOUVIER_DES_FLANDERS",
    BOXER = "BOXER",
    BRACCO_ITALIANO = "BRACCO_ITALIANO",
    BRANDLBRACKE = "BRANDLBRACKE",
    BRAQUE_DU_BOURBONNAIS = "BRAQUE_DU_BOURBONNAIS",
    BRIARD = "BRIARD",
    BRIQUET_GRIFFON_VENDEEN = "BRIQUET_GRIFFON_VENDEEN",
    BRITTANY = "BRITTANY",
    BROHOLMER = "BROHOLMER",
    BRUSSELS_GRIFFON = "BRUSSELS_GRIFFON",
    BULL_TERRIER = "BULL_TERRIER",
    BULLDOG = "BULLDOG",
    BULLDOG_AMERICANO = "BULLDOG_AMERICANO",
    BULLMASTIFF = "BULLMASTIFF",
    CAIRN_TERRIER = "CAIRN_TERRIER",
    CANAAN_DOG = "CANAAN_DOG",
    CANARIAN_WARREN_HOUND = "CANARIAN_WARREN_HOUND",
    CANE_CORSO_ITALIANO = "CANE_CORSO_ITALIANO",
    CAO_DA_SERRA_DA_ESTRELA = "CAO_DA_SERRA_DA_ESTRELA",
    CAO_DA_SERRA_DE_AIRES = "CAO_DA_SERRA_DE_AIRES",
    CAO_DE_CASTRO_LABOREIRO = "CAO_DE_CASTRO_LABOREIRO",
    CATALAN_SHEEPDOG = "CATALAN_SHEEPDOG",
    CAUCASIAN_SHEPHERD_DOG = "CAUCASIAN_SHEPHERD_DOG",
    CAVALIER_KING_CHARLES_SPANIEL = "CAVALIER_KING_CHARLES_SPANIEL",
    CENTRAL_ASIAN_SHEPHERD_DOG = "CENTRAL_ASIAN_SHEPHERD_DOG",
    CESKY_FOUSEK = "CESKY_FOUSEK",
    CESKY_TERRIER = "CESKY_TERRIER",
    CHART_POLSKI = "CHART_POLSKI",
    CHESAPEAKE_BAY_RETRIEVER = "CHESAPEAKE_BAY_RETRIEVER",
    CHIEN_D_ARTOIS = "CHIEN_D_ARTOIS",
    CHIHUAHUA = "CHIHUAHUA",
    CHINESE_CRESTED_HAIRLESS = "CHINESE_CRESTED_HAIRLESS",
    CHOW_CHOW = "CHOW_CHOW",
    CIRNECO_DELL_ETNA = "CIRNECO_DELL_ETNA",
    CLUMBER_SPANIEL = "CLUMBER_SPANIEL",
    COLLIE_ROUGH = "COLLIE_ROUGH",
    COLLIE_SMOOTH = "COLLIE_SMOOTH",
    COTON_DE_TULEAR = "COTON_DE_TULEAR",
    CROATIAN_SHEEPDOG = "CROATIAN_SHEEPDOG",
    CURLY_COATED_RETRIEVER = "CURLY_COATED_RETRIEVER",
    CZECHOSLOVAKIAN_WOLFDOG = "CZECHOSLOVAKIAN_WOLFDOG",
    DACHSHUND_MINIATURE_LONG_HAIRED = "DACHSHUND_MINIATURE_LONG_HAIRED",
    DACHSHUND_MINIATURE_SMOOTH_HAIRED = "DACHSHUND_MINIATURE_SMOOTH_HAIRED",
    DACHSHUND_MINIATURE_WIRE_HAIRED = "DACHSHUND_MINIATURE_WIRE_HAIRED",
    DACHSHUND_RABBIT_LONG_HAIRED = "DACHSHUND_RABBIT_LONG_HAIRED",
    DACHSHUND_RABBIT_SMOOTH_HAIRED = "DACHSHUND_RABBIT_SMOOTH_HAIRED",
    DACHSHUND_RABBIT_WIRE_HAIRED = "DACHSHUND_RABBIT_WIRE_HAIRED",
    DACHSHUND_STANDARD_LONG_HAIRED = "DACHSHUND_STANDARD_LONG_HAIRED",
    DACHSHUND_STANDARD_SMOOTH_HAIRED = "DACHSHUND_STANDARD_SMOOTH_HAIRED",
    DACHSHUND_STANDARD_WIRE_HAIRED = "DACHSHUND_STANDARD_WIRE_HAIRED",
    DALMATIAN = "DALMATIAN",
    DANDIE_DINMONT_TERRIER = "DANDIE_DINMONT_TERRIER",
    DEERHOUND = "DEERHOUND",
    DEUTSCHER_WACHTELHUND = "DEUTSCHER_WACHTELHUND",
    DOBERMAN_PINSCHER = "DOBERMAN_PINSCHER",
    DOGO_ARGENTINO = "DOGO_ARGENTINO",
    DOGUE_DE_BORDEAUX = "DOGUE_DE_BORDEAUX",
    DRENTSE_PATRIJSHOND = "DRENTSE_PATRIJSHOND",
    DREVER = "DREVER",
    DUTCH_SHEPHERD_DOG = "DUTCH_SHEPHERD_DOG",
    EAST_SIBERIAN_LAIKA = "EAST_SIBERIAN_LAIKA",
    ENGLISH_COCKER_SPANIEL = "ENGLISH_COCKER_SPANIEL",
    ENGLISH_FOXHOUND = "ENGLISH_FOXHOUND",
    ENGLISH_POINTER = "ENGLISH_POINTER",
    ENGLISH_SETTER = "ENGLISH_SETTER",
    ENGLISH_SPRINGER_SPANIEL = "ENGLISH_SPRINGER_SPANIEL",
    ENGLISH_TOY_TERRIER = "ENGLISH_TOY_TERRIER",
    ENTLEBUCHER_SENNENHUND = "ENTLEBUCHER_SENNENHUND",
    EURASIER = "EURASIER",
    EUROHOUND = "EUROHOUND",
    FIELD_SPANIEL = "FIELD_SPANIEL",
    FILA_BRASILEIRO = "FILA_BRASILEIRO",
    FINNISH_HOUND = "FINNISH_HOUND",
    FINNISH_LAPPHUND = "FINNISH_LAPPHUND",
    FINNISH_SPITZ = "FINNISH_SPITZ",
    FLAT_COATED_RETRIEVER = "FLAT_COATED_RETRIEVER",
    FOX_TERRIER = "FOX_TERRIER",
    FRENCH_BULLDOG = "FRENCH_BULLDOG",
    FRENCH_POINTING_DOG = "FRENCH_POINTING_DOG",
    FRENCH_SPANIEL = "FRENCH_SPANIEL",
    FRENCH_TRICOLOUR_HOUND = "FRENCH_TRICOLOUR_HOUND",
    FRENCH_WHITE_AND_BLACK_HOUND = "FRENCH_WHITE_AND_BLACK_HOUND",
    FRENCH_WHITE_AND_ORANGE_HOUND = "FRENCH_WHITE_AND_ORANGE_HOUND",
    FRENCH_WIRE_HAIRED_KORTHALS_POINTING_GRIFFON = "FRENCH_WIRE_HAIRED_KORTHALS_POINTING_GRIFFON",
    GALGO_ESPANOL = "GALGO_ESPANOL",
    GERMAN_HOUND = "GERMAN_HOUND",
    GERMAN_HUNT_TERRIER = "GERMAN_HUNT_TERRIER",
    GERMAN_LONG_HAIRED_POINTING_DOG = "GERMAN_LONG_HAIRED_POINTING_DOG",
    GERMAN_PINSCHER = "GERMAN_PINSCHER",
    GERMAN_ROUGH_HAIRED_POINTING_DOG = "GERMAN_ROUGH_HAIRED_POINTING_DOG",
    GERMAN_SHEPHERD_DOG = "GERMAN_SHEPHERD_DOG",
    GERMAN_SHORT_HAIRED_POINTING_DOG = "GERMAN_SHORT_HAIRED_POINTING_DOG",
    GERMAN_SPITZ = "GERMAN_SPITZ",
    GOLDEN_RETRIEVER = "GOLDEN_RETRIEVER",
    GOLDENDOODLE = "GOLDENDOODLE",
    GONCZY_POLSKI = "GONCZY_POLSKI",
    GORDON_SETTER = "GORDON_SETTER",
    GRAND_ANGLO_FRANCAIS = "GRAND_ANGLO_FRANCAIS",
    GRAND_ANGLO_FRANCAIS_BLANC_ET_NOIR = "GRAND_ANGLO_FRANCAIS_BLANC_ET_NOIR",
    GRAND_ANGLO_FRANCAIS_BLANC_ET_ORANGE = "GRAND_ANGLO_FRANCAIS_BLANC_ET_ORANGE",
    GRAND_ANGLO_FRANCAIS_TRICOLORE = "GRAND_ANGLO_FRANCAIS_TRICOLORE",
    GRAND_BASSET_GRIFFON_VENDEEN = "GRAND_BASSET_GRIFFON_VENDEEN",
    GRAND_BLEU_DE_GASCOGNE = "GRAND_BLEU_DE_GASCOGNE",
    GRAND_GASCON_SAINTONGEOIS = "GRAND_GASCON_SAINTONGEOIS",
    GRAND_GRIFFON_VENDEEN = "GRAND_GRIFFON_VENDEEN",
    GREAT_DANE = "GREAT_DANE",
    GREAT_JAPANESE_DOG = "GREAT_JAPANESE_DOG",
    GREATER_SWISS_MOUNTAIN_DOG = "GREATER_SWISS_MOUNTAIN_DOG",
    GREENLAND_DOG = "GREENLAND_DOG",
    GREYHOUND = "GREYHOUND",
    GREYSTER = "GREYSTER",
    GRIFFON_BLEU_DE_GASCOGNE = "GRIFFON_BLEU_DE_GASCOGNE",
    GRIFFON_FAUVE_DE_BRETAGNE = "GRIFFON_FAUVE_DE_BRETAGNE",
    GRIFFON_NIVERNAIS = "GRIFFON_NIVERNAIS",
    HALDEN_HOUND = "HALDEN_HOUND",
    HAMILTON_HOUND = "HAMILTON_HOUND",
    HANOVERIAN_SCENTHOUND = "HANOVERIAN_SCENTHOUND",
    HARRIER = "HARRIER",
    HELLINIKOS_ICHNILATIS = "HELLINIKOS_ICHNILATIS",
    HOKKAIDO = "HOKKAIDO",
    HOLLANDSE_SMOUSHOND = "HOLLANDSE_SMOUSHOND",
    HOVAWART = "HOVAWART",
    HUNGARIAN_SHORT_HAIRED_POINTING_DOG = "HUNGARIAN_SHORT_HAIRED_POINTING_DOG",
    HUNGARIAN_WIRE_HAIRED_POINTING_DOG = "HUNGARIAN_WIRE_HAIRED_POINTING_DOG",
    HYGENHUND = "HYGENHUND",
    IBIZAN_WARREN_HOUND = "IBIZAN_WARREN_HOUND",
    ICELANDIC_SHEEPDOG = "ICELANDIC_SHEEPDOG",
    IRISH_GLEN_OF_IMAAL_TERRIER = "IRISH_GLEN_OF_IMAAL_TERRIER",
    IRISH_RED_AND_WHITE_SETTER = "IRISH_RED_AND_WHITE_SETTER",
    IRISH_RED_SETTER = "IRISH_RED_SETTER",
    IRISH_SOFT_COATED_WHEATEN_TERRIER = "IRISH_SOFT_COATED_WHEATEN_TERRIER",
    IRISH_TERRIER = "IRISH_TERRIER",
    IRISH_WATER_SPANIEL = "IRISH_WATER_SPANIEL",
    IRISH_WOLFHOUND = "IRISH_WOLFHOUND",
    ISTARSKI_KRATKODLAKI_GONIC = "ISTARSKI_KRATKODLAKI_GONIC",
    ISTARSKI_OSTRODLAKI_GONIC = "ISTARSKI_OSTRODLAKI_GONIC",
    ITALIAN_GREYHOUND = "ITALIAN_GREYHOUND",
    ITALIAN_SHEPHERD = "ITALIAN_SHEPHERD",
    JACK_RUSSELL_TERRIER = "JACK_RUSSELL_TERRIER",
    JAPANESE_SPANIEL_CHIN = "JAPANESE_SPANIEL_CHIN",
    JAPANESE_SPITZ = "JAPANESE_SPITZ",
    JAPANESE_TERRIER = "JAPANESE_TERRIER",
    KAI = "KAI",
    KANGAL = "KANGAL",
    KARELIAN_BEAR_DOG = "KARELIAN_BEAR_DOG",
    KEESHOND = "KEESHOND",
    KERRY_BLUE_TERRIER = "KERRY_BLUE_TERRIER",
    KING_CHARLES_SPANIEL = "KING_CHARLES_SPANIEL",
    KISHU = "KISHU",
    KOMONDOR = "KOMONDOR",
    KOOIKERHONDJE = "KOOIKERHONDJE",
    KRASKY_OVCAR = "KRASKY_OVCAR",
    KROMFOHRLANDER = "KROMFOHRLANDER",
    KUVASZ = "KUVASZ",
    LABRADOODLE = "LABRADOODLE",
    LABRADOR_RETRIEVER = "LABRADOR_RETRIEVER",
    LAKELAND_TERRIER = "LAKELAND_TERRIER",
    LANDSEER = "LANDSEER",
    LAPINPOROKOIRA = "LAPINPOROKOIRA",
    LARGE_MUNSTERLANDER = "LARGE_MUNSTERLANDER",
    LEONBERGER = "LEONBERGER",
    LHASA_APSO = "LHASA_APSO",
    LOWCHEN_LITTLE_LION_DOG = "LOWCHEN_LITTLE_LION_DOG",
    MAGYAR_AGAR = "MAGYAR_AGAR",
    MAJORCA_SHEPHERD_DOG = "MAJORCA_SHEPHERD_DOG",
    MALTESE = "MALTESE",
    MALTIPOO = "MALTIPOO",
    MANCHESTER_TERRIER = "MANCHESTER_TERRIER",
    MAREMMA_AND_ABRUZZESE_SHEEPDOG = "MAREMMA_AND_ABRUZZESE_SHEEPDOG",
    MASTIFF = "MASTIFF",
    MINIATURE_AMERICAN_SHEPHERD = "MINIATURE_AMERICAN_SHEPHERD",
    MINIATURE_PINSCHER = "MINIATURE_PINSCHER",
    MINIATURE_POODLE = "MINIATURE_POODLE",
    MINIATURE_SCHNAUZER = "MINIATURE_SCHNAUZER",
    MUDI = "MUDI",
    NEAPOLITAN_MASTIFF = "NEAPOLITAN_MASTIFF",
    NEWFOUNDLAND = "NEWFOUNDLAND",
    NORFOLK_TERRIER = "NORFOLK_TERRIER",
    NORRBOTTENSPETS = "NORRBOTTENSPETS",
    NORWEGIAN_BUHUND = "NORWEGIAN_BUHUND",
    NORWEGIAN_ELKHOUND = "NORWEGIAN_ELKHOUND",
    NORWEGIAN_HOUND = "NORWEGIAN_HOUND",
    NORWEGIAN_LUNDEHUND = "NORWEGIAN_LUNDEHUND",
    NORWICH_TERRIER = "NORWICH_TERRIER",
    NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER = "NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER",
    OLD_DANISH_POINTING_DOG = "OLD_DANISH_POINTING_DOG",
    OLD_ENGLISH_SHEEPDOG = "OLD_ENGLISH_SHEEPDOG",
    OTTERHOUND = "OTTERHOUND",
    PAPILLON = "PAPILLON",
    PARSON_RUSSELL_TERRIER = "PARSON_RUSSELL_TERRIER",
    PASTORE_DELLA_LESSINIA_O_LAGORAI = "PASTORE_DELLA_LESSINIA_O_LAGORAI",
    PEKINGESE = "PEKINGESE",
    PERDIGUERO_DE_BURGOS = "PERDIGUERO_DE_BURGOS",
    PERRO_DOGO_MALLORQUIN = "PERRO_DOGO_MALLORQUIN",
    PERUVIAN_HAIRLESS_DOG = "PERUVIAN_HAIRLESS_DOG",
    PETIT_BASSET_GRIFFON_VENDEEN = "PETIT_BASSET_GRIFFON_VENDEEN",
    PETIT_BLEU_DE_GASCOGNE = "PETIT_BLEU_DE_GASCOGNE",
    PETIT_BRABANCON = "PETIT_BRABANCON",
    PETIT_GASCON_SAINTONGEOIS = "PETIT_GASCON_SAINTONGEOIS",
    PHARAOH_HOUND = "PHARAOH_HOUND",
    PICARDY_SPANIEL = "PICARDY_SPANIEL",
    PODENGO_PORTUGUESOS_GRANDE = "PODENGO_PORTUGUESOS_GRANDE",
    PODENGO_PORTUGUESOS_MEDIO = "PODENGO_PORTUGUESOS_MEDIO",
    PODENGO_PORTUGUESOS_PEQUENO = "PODENGO_PORTUGUESOS_PEQUENO",
    POITEVIN = "POITEVIN",
    POLISH_HOUND = "POLISH_HOUND",
    POLSKI_OWCZAREK_NIZINNY = "POLSKI_OWCZAREK_NIZINNY",
    POLSKI_OWCZAREK_PODHALANSKI = "POLSKI_OWCZAREK_PODHALANSKI",
    POMERANIAN = "POMERANIAN",
    POODLE = "POODLE",
    PORCELAINE = "PORCELAINE",
    PORTUGUESE_POINTING_DOG = "PORTUGUESE_POINTING_DOG",
    PORTUGUESE_WATER_DOG = "PORTUGUESE_WATER_DOG",
    POSAVAZ_HOUND = "POSAVAZ_HOUND",
    PUDELPOINTER = "PUDELPOINTER",
    PUG = "PUG",
    PULI = "PULI",
    PUMI = "PUMI",
    PYRENEAN_MASTIFF = "PYRENEAN_MASTIFF",
    PYRENEAN_MOUNTAIN_DOG = "PYRENEAN_MOUNTAIN_DOG",
    RAFEIRO_DO_ALENTEJO = "RAFEIRO_DO_ALENTEJO",
    RHODESIAN_RIDGEBACK = "RHODESIAN_RIDGEBACK",
    ROTTWEILER = "ROTTWEILER",
    RUSSIAN_BLACK_TERRIER = "RUSSIAN_BLACK_TERRIER",
    RUSSIAN_EUROPEAN_LAIKA = "RUSSIAN_EUROPEAN_LAIKA",
    SAARLOOSWOLFHOND = "SAARLOOSWOLFHOND",
    SAINT_BERNARD = "SAINT_BERNARD",
    SALUKI = "SALUKI",
    SAMOYED = "SAMOYED",
    SARPLANINAC = "SARPLANINAC",
    SCHAPENDOES = "SCHAPENDOES",
    SCHILLER_HOUND = "SCHILLER_HOUND",
    SCHIPPERKE = "SCHIPPERKE",
    SCOTTISH_TERRIER = "SCOTTISH_TERRIER",
    SEALYHAM_TERRIER = "SEALYHAM_TERRIER",
    SHAR_PEI = "SHAR_PEI",
    SHETLAND_SHEEPDOG_SHELTIE = "SHETLAND_SHEEPDOG_SHELTIE",
    SHIBA_INU = "SHIBA_INU",
    SHIH_TZU = "SHIH_TZU",
    SHIKOKU = "SHIKOKU",
    SIBERIAN_HUSKY = "SIBERIAN_HUSKY",
    SKYE_TERRIER = "SKYE_TERRIER",
    SLOUGHI = "SLOUGHI",
    SLOVAKIAN_HOUND = "SLOVAKIAN_HOUND",
    SLOVENSKY_CUVAC = "SLOVENSKY_CUVAC",
    SLOVENSKY_HRUBOSRSTY_STAVAC = "SLOVENSKY_HRUBOSRSTY_STAVAC",
    SMALANDSSTOVARE = "SMALANDSSTOVARE",
    SMALL_MUNSTERLANDER = "SMALL_MUNSTERLANDER",
    SMALL_SWISS_HOUND = "SMALL_SWISS_HOUND",
    SOUTH_RUSSIAN_SHEPHERD_DOG = "SOUTH_RUSSIAN_SHEPHERD_DOG",
    SPANIEL_DE_PONT_AUDEMER = "SPANIEL_DE_PONT_AUDEMER",
    SPANISH_GRAN_DANE = "SPANISH_GRAN_DANE",
    SPANISH_HOUND = "SPANISH_HOUND",
    SPANISH_MASTIFF = "SPANISH_MASTIFF",
    SPANISH_WATER_DOG = "SPANISH_WATER_DOG",
    SPINONE_ITALIANO = "SPINONE_ITALIANO",
    SRPSKI_GONIC = "SRPSKI_GONIC",
    SRPSKI_PLANINSKI_GONIC = "SRPSKI_PLANINSKI_GONIC",
    SRPSKI_TROBOJNI_GONIC = "SRPSKI_TROBOJNI_GONIC",
    ST_GERMAIN_POINTING_DOG = "ST_GERMAIN_POINTING_DOG",
    STABYHOUN = "STABYHOUN",
    STAFFORDSHIRE_BULL_TERRIER = "STAFFORDSHIRE_BULL_TERRIER",
    STYRIAN_COARSE_HAIRED_HOUND = "STYRIAN_COARSE_HAIRED_HOUND",
    SUSSEX_SPANIEL = "SUSSEX_SPANIEL",
    SWEDISH_ELKHOUND = "SWEDISH_ELKHOUND",
    SWEDISH_LAPPHUND = "SWEDISH_LAPPHUND",
    SWEDISH_VALLHUND = "SWEDISH_VALLHUND",
    SWISS_HOUND = "SWISS_HOUND",
    TCHIORNY_TERRIER = "TCHIORNY_TERRIER",
    THAI_RIDGEBACK = "THAI_RIDGEBACK",
    TIBETAN_MASTIFF = "TIBETAN_MASTIFF",
}

import * as Types from '../../../../types.js';

import { UserTreatmentFragment } from './user-treatment.generated';
import { gql } from '@apollo/client';
import { UserTreatmentFragmentDoc } from './user-treatment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUserTreatmentsQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  custodyLevels?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
}>;


export type GetUserTreatmentsQuery = (
  { __typename?: 'Query' }
  & { listTreatments: (
    { __typename?: 'PaginatedTreatments' }
    & Pick<Types.PaginatedTreatments, 'success'>
    & { error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'code' | 'message' | 'extra'>
    )>, pagination: (
      { __typename?: 'Pagination' }
      & Pick<Types.Pagination, 'page_size' | 'current_page' | 'total_pages' | 'total_items'>
    ), items: Array<Types.Maybe<(
      { __typename?: 'Treatment' }
      & UserTreatmentFragment
    )>> }
  ) }
);


export const GetUserTreatmentsDocument = gql`
    query getUserTreatments($userId: String!, $page: Int!, $custodyLevels: [String] = []) {
  listTreatments(
    commonSearch: {page: $page, order_by: "date", filters: {join: [{key: "health_cards", value: {join: [{key: "pets", value: {join: [{key: "ownerships", value: {fixed: [{key: "user_id", value: $userId}], lists: [{key: "custody_level", value: $custodyLevels}]}}]}}]}}]}}
  ) {
    success
    error {
      code
      message
      extra
    }
    pagination {
      page_size
      current_page
      total_pages
      total_items
    }
    items {
      ...UserTreatment
    }
  }
}
    ${UserTreatmentFragmentDoc}`;

/**
 * __useGetUserTreatmentsQuery__
 *
 * To run a query within a React component, call `useGetUserTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTreatmentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      page: // value for 'page'
 *      custodyLevels: // value for 'custodyLevels'
 *   },
 * });
 */
export function useGetUserTreatmentsQuery(baseOptions: Apollo.QueryHookOptions<GetUserTreatmentsQuery, GetUserTreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTreatmentsQuery, GetUserTreatmentsQueryVariables>(GetUserTreatmentsDocument, options);
      }
export function useGetUserTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTreatmentsQuery, GetUserTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTreatmentsQuery, GetUserTreatmentsQueryVariables>(GetUserTreatmentsDocument, options);
        }
export type GetUserTreatmentsQueryHookResult = ReturnType<typeof useGetUserTreatmentsQuery>;
export type GetUserTreatmentsLazyQueryHookResult = ReturnType<typeof useGetUserTreatmentsLazyQuery>;
export type GetUserTreatmentsQueryResult = Apollo.QueryResult<GetUserTreatmentsQuery, GetUserTreatmentsQueryVariables>;
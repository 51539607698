import * as Types from '../../../../types.js';

import { gql } from '@apollo/client';
export type UserOwnershipsFragment = (
  { __typename?: 'PaginatedOwnerships' }
  & Pick<Types.PaginatedOwnerships, 'success'>
  & { items: Array<Types.Maybe<(
    { __typename?: 'Ownership' }
    & Pick<Types.Ownership, 'id' | 'custody_level'>
    & { pet: (
      { __typename?: 'Pet' }
      & Pick<Types.Pet, 'birthday' | 'chip_code' | 'diet' | 'disciplines' | 'gender' | 'id' | 'intollerance' | 'name' | 'neutered' | 'temperament' | 'weight_kg'>
    ) }
  )>>, pagination: (
    { __typename?: 'Pagination' }
    & Pick<Types.Pagination, 'current_page' | 'page_size' | 'total_items' | 'total_pages'>
  ) }
);

export const UserOwnershipsFragmentDoc = gql`
    fragment UserOwnerships on PaginatedOwnerships {
  items {
    id
    custody_level
    pet {
      birthday
      chip_code
      diet
      disciplines
      gender
      id
      intollerance
      name
      neutered
      temperament
      weight_kg
    }
  }
  success
  pagination {
    current_page
    page_size
    total_items
    total_pages
  }
}
    `;
import styled from "styled-components";


export const Section = styled.section`
    width: 100%;
    height: 100%;
    padding-left: 54px;
    padding-right: 54px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-between;
    @media screen and (max-width: 600px){
        padding-left:10px;
        padding-right: 10px;
    }
`

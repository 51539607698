import React, { useContext } from 'react'

import { ThemesContext, ColorName } from '../system/themes'
import { IconSelect, IconName, iconNames as _iconNames } from './SpecialIcons'

export type IconNames = IconName
export const iconNames = _iconNames

type Props = {
  name: IconName
  color?: ColorName
  size?: string
  style?: Record<string, unknown>
  className?: string
}
export const SpecialIcon = ({ name, color = 'white', size = '1em', style, className }: Props) => {
  const themes = useContext(ThemesContext)
  return (
    <IconSelect
      name={name}
      iconProps={{
        color: themes.theme[color],
        style: { width: size, height: size, ...style },
        className,
      }}
    />
  )
}

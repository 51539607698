import * as Types from '../../../../types.js';

import { UserOwnershipsFragment } from './user-ownerships.generated';
import { gql } from '@apollo/client';
import { UserOwnershipsFragmentDoc } from './user-ownerships.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUserOwnershipQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  commonSearch: Types.CommonSearch;
}>;


export type GetUserOwnershipQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserResult' }
    & Pick<Types.UserResult, 'success'>
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & { ownerships?: Types.Maybe<(
        { __typename?: 'PaginatedOwnerships' }
        & UserOwnershipsFragment
      )> }
    )>, error?: Types.Maybe<(
      { __typename?: 'Error' }
      & Pick<Types.Error, 'code' | 'message' | 'extra'>
    )> }
  ) }
);


export const GetUserOwnershipDocument = gql`
    query getUserOwnership($id: ID!, $commonSearch: CommonSearch!) {
  getUser(id: $id) {
    user {
      ownerships(commonSearch: $commonSearch) {
        ...UserOwnerships
      }
    }
    success
    error {
      code
      message
      extra
    }
  }
}
    ${UserOwnershipsFragmentDoc}`;

/**
 * __useGetUserOwnershipQuery__
 *
 * To run a query within a React component, call `useGetUserOwnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOwnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOwnershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *      commonSearch: // value for 'commonSearch'
 *   },
 * });
 */
export function useGetUserOwnershipQuery(baseOptions: Apollo.QueryHookOptions<GetUserOwnershipQuery, GetUserOwnershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOwnershipQuery, GetUserOwnershipQueryVariables>(GetUserOwnershipDocument, options);
      }
export function useGetUserOwnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOwnershipQuery, GetUserOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOwnershipQuery, GetUserOwnershipQueryVariables>(GetUserOwnershipDocument, options);
        }
export type GetUserOwnershipQueryHookResult = ReturnType<typeof useGetUserOwnershipQuery>;
export type GetUserOwnershipLazyQueryHookResult = ReturnType<typeof useGetUserOwnershipLazyQuery>;
export type GetUserOwnershipQueryResult = Apollo.QueryResult<GetUserOwnershipQuery, GetUserOwnershipQueryVariables>;
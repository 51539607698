import { useTable } from "react-table";
import { PaginatedResponse } from "../../../utils/types";
import styled from "styled-components";
import { useTheme } from "../themes";
import { Flex } from "./Flex";
import Row, { Tr } from "./Row";
import { Loader } from "../../common/Loader";
import { Label } from "../Typography";
import { useEffect } from "react";

interface Props<T> {
  data?: PaginatedResponse<T> | null;
  columns: any[];
  loading?: boolean;
}

const TableComponent = ({ data, columns, loading = false }: Props<any>) => {
  const themes = useTheme();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: data?.items || [],
    });
  useEffect(()=> {
    console.log(data)
  }, [data])
  return (
    <TableContainer>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <Tr
              strippedColor={themes.theme["white"]}
              hoverColor={themes.theme["white"]}
              color={themes.theme["white"]}
              className="header"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="headTable"
                  style={{
                    maxWidth: `${column.maxWidth}px`,
                    minWidth: `${column.minWidth}px`,
                    width: column.width,
                    position: 'sticky',
                    top:'-1px',
                    zIndex:2,
                    
                  }}
                  {...column.getHeaderProps()}
                >
                  <Flex justifyContent="center" padding="16px" style={{ }}>
                    {column.render("Header")}
                  </Flex>
                </th>
              ))}
            </Tr>
          ))}
        </thead>

        {data && !loading && (
          <tbody style={{}} {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return <Row key={`row-${index}`} row={row} index={index} />;
            })}
          </tbody>
        )}
      </Table>
      {loading &&  
        <Loader size="medium" />
        
      }
      { !loading && (!data || data.items.length === 0 ) && <Label  text="table.no_items" />}
    </TableContainer>
  );
};

export default TableComponent;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 65vh;
  overflow-y: scroll;
  overflow-x: clip;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
`;

export const Table = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 650px;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow-x: auto;
  margin-bottom:30px;
`;

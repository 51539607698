import { ColorName } from '../../components/system/themes'
import { CustodyLevel, TreatmentType } from '../../types'

export const roleColors: Record<CustodyLevel, ColorName> = {
  OWNER: 'primary',
  SUB_OWNER: 'secondary',
  PET_SITTER: 'quaternary',
}

export const treatmentColors: Record<TreatmentType, ColorName> = {
  VACCINE: 'primaryDark',
  ANTIPARASITIC: 'secondaryDark',
  OPERATION: "tertiaryDark",
  REMINDER: "quaternaryDark",
  TABLET: "tertiary"

}


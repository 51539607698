import { useCallback, useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import useOnClickOutside from "../../hooks/useOnClickOutseide";
import { I18NKey } from "../../i18n";
import { ColorName, useTheme } from "./themes";
import { Label } from "./Typography";
import dayjs from 'dayjs'
import it from 'date-fns/locale/it'
import _ from 'lodash'
type props = {
    label?: I18NKey;
    control: any;
    name: string;
    color?: ColorName;
    disabledColor?: ColorName;
    selectedColor?: ColorName;
    inputBackgroundColor?: ColorName;
    borderColor?: ColorName;
    calendarBackgroundColor?: ColorName;
    hoverColor?: ColorName;
    focusColor?: ColorName;
};

type Size = "small" | "medium" | "large";

export const DateInput: React.FC<props> = ({
    color = "black",
    inputBackgroundColor = "white",
    selectedColor = "primary",
    borderColor = "gray",
    calendarBackgroundColor = "white",
    hoverColor = "secondary",
    disabledColor = "grayLight",
    control,
    name,
    focusColor = "primary",
}) => {
    const themes = useTheme();
    const [open, setOpen] = useState(false);
    const [calendarDate, setCalendarDate] = useState(new Date())
    const [up, setUp] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => {
        setOpen(false)
        const hide = ref.current?.children[0]
        if(!hide ) return
        hide.classList.remove('hide')
    });

    const openModal = useCallback((e: any) => {
        setOpen(true);
        setUp(
            ref.current?.children[0].clientHeight + e.clientY >
                window.innerHeight
        );
    }, []);
    
    const syncDates= useCallback(()=>{
        setCalendarDate(_.get(control._fields, name)._f.value)
    },[control._fields, name])

    const handleYearClick = (e:any)=> {
        const hide = ref.current?.children[0]
        if(!hide ) return
        hide.classList.add('hide')
        
    }

    const setYear = (e:any)=> {
        const hide = ref.current?.children[0]
        if(!hide ) return
        setCalendarDate(date=> {return dayjs(date).set("years", parseInt(e.target.innerHTML)).toDate() })
        hide.classList.remove('hide')
    }
    useEffect(()=> {
        syncDates()
    },[open, syncDates] )


    useEffect(()=> {

        const calendar = ref.current?.children[0].children[0].children[0].children[0].children[0]!
        calendar.addEventListener('click', handleYearClick);
        const hide = ref.current?.children[0]
        if(!hide ) return

        const main = hide.children[0].children[0];
        main.appendChild(document.createElement('div'))
        main.children[2].classList.add('yearSelector')
        main.children[2].innerHTML =_.range(1950, new Date().getFullYear()+1).map((year)=> `<p>${year}</p>`).reverse().join("")
        const years = main.children[2].children
        Array.from(years).map((el)=> el.addEventListener('click', setYear))
        return () => {
            calendar.removeEventListener('click', handleYearClick);
          };
    }, [])

    return (
        <Container>
            <Label />
            <Controller name={name} control={control} render={({ field: { onChange, value, name },  }) => {
                return (
            <>
            <InputContainer
                borderColor={
                    open ? themes.theme[focusColor] : themes.theme[borderColor]
                }
            >
                <StyledInput
                    padding="small"
                    blurredColor={themes.theme.gray}
                    disabledBackgroundColor={themes.theme[inputBackgroundColor]}
                    backgroundColor={themes.theme[inputBackgroundColor]}
                    textColor={themes.theme.black}
                    value= {dayjs(value).format("ll")}
                    onClick={openModal}
                    readOnly
                />
            </InputContainer>
            <CalendarContainer
                ref={ref}
                className={`${open ? "open" : ""} ${up ? "up" : ""}`}
            >   
                { <StyledDayPicker
                    hoverColor={themes.theme[hoverColor]}
                    color={themes.theme[color]}
                    locale={it}
                    disabledColor={themes.theme[disabledColor]}
                    selectedColor= {themes.theme[selectedColor]}
                    borderColor={themes.theme[borderColor]}
                    backgroundColor={themes.theme[calendarBackgroundColor]}
                    mode="single"
                    showOutsideDays
                    fixedWeeks
                    month={new Date(dayjs(calendarDate).year(), dayjs(calendarDate).month() )}
                    onMonthChange={(selected)=>  setCalendarDate(selected)}
                    selected={ dayjs(value).toDate()}
                    onSelect={(selected: any) => {
                        setCalendarDate(selected)
                        onChange(dayjs(selected).format("YYYY-MM-DD HH:mm:ss"))
                        setOpen(false)
                    }}
                />}
            </CalendarContainer>
        </>
        )}} />
        </Container>
    );
};


const Container = styled.div`
    position: relative;
`;


type Padding = { [key in Size]: { x: number; y: number } };
const paddings: Padding = {
    small: { x: 10, y: 2 },
    medium: { x: 12, y: 5 },
    large: { x: 16, y: 8 },
};

type StyledProps = {
    textColor: string;
    blurredColor: string;
    padding: Size;
    backgroundColor: string;
    disabledBackgroundColor: string;
    errorColor?: string;
    disabled?: boolean;
};

const InputContainer = styled.div<{ borderColor: string }>`
    border: 0.5px solid ${({ borderColor }) => borderColor};
    display: flex;
    border-radius: 4px;
`;

const StyledInput = styled.input<StyledProps>`
    font-family: Karla;
    font-size: 13px;
    height: 43px;
    line-height: 18px;
    border-radius: 4px;
    font-weight: 400;
    color: ${(props) =>
        props.disabled ? props.blurredColor : props.textColor};
    background-color: ${(props) =>
        props.disabled ? props.disabledBackgroundColor : props.backgroundColor};
    border: none;
    margin: 0;
    /* box-sizing: border-box; */
    padding: ${(props) => paddings[props.padding].y}px
        ${(props) => paddings[props.padding].x}px;
    /* border: ${(props) => paddings[props.padding].x}px ${(props) =>
        paddings[props.padding].y}px solid
        ${(props) =>
        props.disabled
            ? props.disabledBackgroundColor
            : props.backgroundColor}; */
    outline: none;
    flex-grow: 2;
    ::-webkit-input-placeholder {
        color: ${(props) => props.blurredColor};
    }
    ::placeholder {
        color: ${(props) => props.blurredColor};
    }
    :disabled {
        background-color: ${(props) => props.disabledBackgroundColor};
    }
`;

const CalendarContainer = styled.div`
    overflow-y: hidden;
    max-height: 0;
    transition: 1s max-height ease-in;
    &.open {
        position: absolute;
        z-index: 100;
        max-height: unset;
    }
    &.up {
        bottom: 50px;
    }
`;


type DayPickerProps = {
    backgroundColor: string;
    borderColor: string;
    color: string;
    disabledColor: string;
    hoverColor: string;
    selectedColor: string;
};

const StyledDayPicker = styled(DayPicker)<DayPickerProps>`
    border-radius: 4px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: 1px solid ${({ borderColor }) => borderColor};
    color: ${({ color }) => color};
    padding: 10px;
    height: 358px;
    margin: 0;
    * {
        transition: opacity .6s ease-in;
    }
    .yearSelector { 
        height:0;
        opacity: 0;
    }
    &.hide {
            .rdp-caption {

            pointer-events: none;
            opacity: .5;
            }
            .rdp-table {
            pointer-events: none;
            opacity: 0;
            }

        .yearSelector {
            width :calc(100% - 1px);
            opacity: 1;
            height: calc(100% - 60px);
            overflow-y: scroll;
            left:1px;
            top: calc(0px + 60px);
            position:absolute;
            pointer-events: all;
            > p {
                width: 100%;
                text-align: center;
                margin-top: 0;
                margin-bottom: 10px;
                padding: 10px;
                box-sizing: border-box;
                cursor: pointer;
                &:hover{
                    color: #fafafa;
                    background-color: ${({hoverColor})=>hoverColor };
                }
            }
        }
    }
    
    
    
    .rdp-nav{
        button{
            &:hover {
                background-color: ${({hoverColor})=>hoverColor };
                svg {
                    color: #fafafa;
                }
            }
            &:active {
                border: none;
                background-color: ${({hoverColor})=>hoverColor };
            }
        }
    }
    .rdp-tbody {
        .rdp-row {
            .rdp-cell {
                .rdp-day_selected {
                    background-color: ${({selectedColor})=> selectedColor};
                    color: #fafafa;
                }
                .rdp-day_outside {
                    color:${({disabledColor})=>disabledColor };
                }
                .rdp-button {
                    &:hover {
                        background-color: ${({ hoverColor }) => hoverColor};
                        color: #fafafa;
                    }
                    &:active{
                        background-color: ${({ hoverColor }) => hoverColor};
                        color: #fafafa;
                        border: none;
                    }
                }
            }
        }
    }
`;

